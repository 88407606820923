var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.relatorio.VacasHiperqueratose ||
    _vm.relatorio.CasosSobreordenha ||
    _vm.relatorio.VacasEscoreLimpeza ||
    _vm.relatorio.VacasSujas ||
    _vm.relatorio.TempoMedioPreparo ||
    _vm.relatorio.TempoMedioOrdenha
  )?_c('v-card',{staticClass:"ma-4"},[_c('v-card-title',{staticStyle:{"text-align":"left"}},[_vm._v("Informações de ordenha")]),_c('v-card-text',[_c('v-row',{staticClass:"pa-3"},[(
          _vm.relatorio.VacasHiperqueratose || _vm.relatorio.VacasHiperqueratose == ''
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12","sm":"6","lg":"6","xl":"6"}},[_c('span',{staticClass:"key-title"},[_vm._v(" Número de vacas com hiperqueratose de teto: ")]),_c('span',{staticClass:"value-title"},[_vm._v(" "+_vm._s(_vm.relatorio.VacasHiperqueratose || '-')+" ")])]):_vm._e(),(
          _vm.relatorio.CasosSobreordenha || _vm.relatorio.CasosSobreordenha == ''
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12","sm":"6","lg":"6","xl":"6"}},[_c('span',{staticClass:"key-title"},[_vm._v("Número de casos de sobreordenha: ")]),_c('span',{staticClass:"value-title"},[_vm._v(" "+_vm._s(_vm.relatorio.CasosSobreordenha || '-')+" ")])]):_vm._e(),(
          _vm.relatorio.VacasEscoreLimpeza || _vm.relatorio.VacasEscoreLimpeza == ''
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12","sm":"6","lg":"6","xl":"6"}},[_c('span',{staticClass:"key-title"},[_vm._v(" Número de vacas escore de limpeza da ponta do teto 3 e 4: ")]),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.VacasEscoreLimpeza || '-'))])]):_vm._e(),(_vm.relatorio.VacasSujas || _vm.relatorio.VacasSujas == '')?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12","sm":"6","lg":"6","xl":"6"}},[_c('span',{staticClass:"key-title"},[_vm._v(" Número de vacas sujas (escore de sujidade 3 e 4): ")]),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.VacasSujas || '-'))])]):_vm._e(),(
          _vm.relatorio.TempoMedioPreparo || _vm.relatorio.TempoMedioPreparo == ''
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12","sm":"6","lg":"6","xl":"6"}},[_c('span',{staticClass:"key-title"},[_vm._v("Tempo médio de preparo: ")]),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.TempoMedioPreparo || '-'))])]):_vm._e(),(
          _vm.relatorio.TempoMedioOrdenha || _vm.relatorio.TempoMedioOrdenha == ''
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12","sm":"6","lg":"6","xl":"6"}},[_c('span',{staticClass:"key-title"},[_vm._v("Tempo médio de ordenha: ")]),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.TempoMedioOrdenha || '-'))])]):_vm._e()],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }