<template>
  <v-card class="ma-4">
    <v-card-title style="text-align: left">Informações básicas</v-card-title>
    <v-card-text>
      <v-row class="pa-3">
        <v-col cols="12" sm="5" lg="5" xl="5" class="col-bordered">
          <span class="key-title">Produtor(a):</span>
          <span class="value-title">{{ relatorio.fazenda.produtor.Nome }}</span>
        </v-col>
        <v-col cols="12" sm="4" lg="4" xl="4" class="col-bordered">
          <span class="key-title">Matrícula: </span>
          <span class="value-title">{{
            relatorio.fazenda.producer.Matricula || '-'
          }}</span>
        </v-col>
        <v-col cols="12" sm="3" lg="3" xl="3" class="col-bordered">
          <span class="key-title">Horário do ínicio: </span>
          <span class="value-title">{{ relatorio.HoraInicio }}</span>
        </v-col>
        <v-col cols="12" sm="9" lg="9" xl="9" class="col-bordered">
          <span class="key-title">Fazenda: </span>
          <span class="value-title">{{
            relatorio.fazenda.pessoajuridica.NomeFantasia
          }}</span>
        </v-col>
        <v-col cols="12" sm="3" lg="3" xl="3" class="col-bordered">
          <span class="key-title">Horário do término: </span>
          <span class="value-title">{{ relatorio.HoraFim }}</span>
        </v-col>
        <v-col cols="12" sm="9" lg="9" xl="9" class="col-bordered">
          <span class="key-title">Cidade: </span>
          <span class="value-title">{{
            relatorio.cidadefazenda ? relatorio.cidadefazenda.Nome : '-'
          }}</span>
        </v-col>
        <v-col cols="12" sm="3" lg="3" xl="3" class="col-bordered">
          <span class="key-title">Data: </span>
          <span class="value-title">{{ relatorio.DataAtendimento }}</span>
        </v-col>
        <v-col cols="12" sm="6" lg="6" xl="6" class="col-bordered">
          <span class="key-title">Consultor(a): </span>
          <span class="value-title">{{
            relatorio.consultor.pessoafisica.Nome
          }}</span>
        </v-col>
        <v-col cols="12" sm="6" lg="6" xl="6" class="col-bordered">
          <span class="key-title">Líder do projeto: </span>
          <span class="value-title">{{ relatorio.LiderProjeto || '-' }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'InformacoesGerais',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
