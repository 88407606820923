<template>
  <v-card
    v-if="
      (relatorio.ImagensInformacoesOrdenha != null &&
        relatorio.ImagensInformacoesOrdenha.length) ||
      (relatorio.ImagensInformacoesDeRotinaDeOrdenha != null &&
        relatorio.ImagensInformacoesDeRotinaDeOrdenha.length) ||
      (relatorio.ImagensBiosegurancaAmbienteImunidade != null &&
        relatorio.ImagensBiosegurancaAmbienteImunidade.length) ||
      (relatorio.ImagensNutricaoDoRebanho != null &&
        relatorio.ImagensNutricaoDoRebanho.length) ||
      (relatorio.ImagensProtologoDeTratamento != null &&
        relatorio.ImagensProtologoDeTratamento.length)
    "
    class="ma-4"
  >
    <v-card-title style="text-align: left">Imagens em anexo</v-card-title>
    <v-card-text>
      <v-row class="pa-3">
        <v-col
          v-if="
            relatorio.ImagensInformacoesOrdenha != null &&
            relatorio.ImagensInformacoesOrdenha.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Imagens informações de ordenha</span>
          <br />
          <v-chip
            v-for="(imagem, index) in relatorio.ImagensInformacoesOrdenha"
            :key="index"
            class="ma-1"
          >
            <v-img
              v-if="imagem.id"
              :src="getImagem(imagem.id)"
              contain
              max-width="500px"
              max-height="500px"
              alt="imagem"
            />
          </v-chip>
        </v-col>
        <v-col
          v-if="
            relatorio.ImagensInformacoesDeRotinaDeOrdenha != null &&
            relatorio.ImagensInformacoesDeRotinaDeOrdenha.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Imagens recomendações - rotina de ordenha</span
          >
          <br />
          <v-chip
            v-for="(
              imagem, index
            ) in relatorio.ImagensInformacoesDeRotinaDeOrdenha"
            :key="index"
            class="ma-1"
          >
            <v-img
              v-if="imagem.id"
              :src="getImagem(imagem.id)"
              contain
              max-width="500px"
              max-height="500px"
              alt="imagem"
            />
          </v-chip>
        </v-col>
        <v-col
          v-if="
            relatorio.ImagensBiosegurancaAmbienteImunidade != null &&
            relatorio.ImagensBiosegurancaAmbienteImunidade.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Imagens biossegurança / ambiente / imunidade</span
          >
          <br />
          <v-chip
            v-for="(
              imagem, index
            ) in relatorio.ImagensBiosegurancaAmbienteImunidade"
            :key="index"
            class="ma-1"
          >
            <v-img
              v-if="imagem.id"
              :src="getImagem(imagem.id)"
              contain
              max-width="500px"
              max-height="500px"
              alt="imagem"
            />
          </v-chip>
        </v-col>
        <v-col
          v-if="
            relatorio.ImagensNutricaoDoRebanho != null &&
            relatorio.ImagensNutricaoDoRebanho.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Imagens nutrição do rebanho</span>
          <br />
          <v-chip
            v-for="(imagem, index) in relatorio.ImagensNutricaoDoRebanho"
            :key="index"
            class="ma-1"
          >
            <v-img
              v-if="imagem.id"
              :src="getImagem(imagem.id)"
              contain
              max-width="500px"
              max-height="500px"
              alt="imagem"
            />
          </v-chip>
        </v-col>
        <v-col
          v-if="
            relatorio.ImagensProtologoDeTratamento != null &&
            relatorio.ImagensProtologoDeTratamento.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Imagens protocolo de tratamento</span>
          <br />
          <v-chip
            v-for="(imagem, index) in relatorio.ImagensProtologoDeTratamento"
            :key="index"
            class="ma-1"
          >
            <v-img
              v-if="imagem.id"
              :src="getImagem(imagem.id)"
              contain
              max-width="500px"
              max-height="500px"
              alt="imagem"
            />
          </v-chip>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { ENV_APP } from '../../../../../env'
export default {
  name: 'ImagensAnexo',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {
    maskIdentify(raw) {
      return raw ? raw.split('|') : ['Nenhum animal identificado']
    },
    getImagem(idImagem) {
      return `${ENV_APP.apiUri}/download-image/${idImagem}`
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
