<template>
  <v-card
    v-if="
      (relatorio.AnimaisOrdenhadosPorUltimo != null &&
        relatorio.AnimaisOrdenhadosPorUltimo.length != 0) ||
      (relatorio.AnimaisParaDescarte != null &&
        relatorio.AnimaisParaDescarte.length != 0) ||
      (relatorio.AnimaisEQuartosMamariosInativacao != null &&
        relatorio.AnimaisEQuartosMamariosInativacao.length != 0) ||
      (relatorio.VacinacaoProximoMes != null &&
        relatorio.VacinacaoProximoMes.length != 0) ||
      relatorio.QtdeAnimaisComClaudLactacao != '' ||
      relatorio.QtdeAnimaisComClaudSecas != '' ||
      relatorio.TaxaRetencaoPlacenta != '' ||
      relatorio.QtdeAnimaisComEstresseCalorico != ''
    "
    class="ma-4"
  >
    <v-card-title style="text-align: left"
      >Biossegurança / Ambiente / Imunidade</v-card-title
    >
    <v-card-text>
      <v-row class="pa-3">
        <v-col
          v-if="relatorio.QtdeAnimaisClaudicacao"
          cols="6"
          class="col-bordered"
        >
          <span class="key-title"> Número de animais com claudicação </span>
          <br />
          <span class="value-title">{{
            relatorio.QtdeAnimaisClaudicacao
          }}</span>
        </v-col>
        <v-col
          v-if="relatorio.QtdeAnimaisEstresse"
          cols="6"
          class="col-bordered"
        >
          <span class="key-title">
            Número de animais com estresse calórico
          </span>
          <br />
          <span class="value-title">{{ relatorio.QtdeAnimaisEstresse }}</span>
        </v-col>
        <v-col
          v-if="relatorio.QtdeAnimaisComClaudLactacao"
          cols="12"
          sm="3"
          lg="3"
          xl="3"
          class="col-bordered"
        >
          <span class="key-title">
            Taxa de claudicação das vacas em lactação
          </span>
          <br />
          <span class="value-title">{{
            relatorio.QtdeAnimaisComClaudLactacao + ' %'
          }}</span>
        </v-col>
        <v-col
          v-if="relatorio.QtdeAnimaisComClaudSecas"
          cols="12"
          sm="3"
          lg="3"
          xl="3"
          class="col-bordered"
        >
          <span class="key-title"> Taxa de claudicação das vacas secas </span>
          <br />
          <span class="value-title">{{
            relatorio.QtdeAnimaisComClaudSecas + ' %'
          }}</span>
        </v-col>
        <v-col
          v-if="relatorio.TaxaRetencaoPlacenta"
          cols="12"
          sm="3"
          lg="3"
          xl="3"
          class="col-bordered"
        >
          <span class="key-title">Taxa de retenção de placenta</span><br />
          <span class="value-title">{{
            relatorio.TaxaRetencaoPlacenta + ' %'
          }}</span>
        </v-col>
        <v-col
          v-if="relatorio.QtdeAnimaisComEstresseCalorico"
          cols="12"
          sm="3"
          lg="3"
          xl="3"
          class="col-bordered"
        >
          <span class="key-title"> Taxa de animais com estresse calórico </span>
          <br />
          <span class="value-title">{{
            relatorio.QtdeAnimaisComEstresseCalorico + ' %'
          }}</span>
        </v-col>
        <v-col
          v-if="
            relatorio.AnimaisOrdenhadosPorUltimo != null &&
            relatorio.AnimaisOrdenhadosPorUltimo.length != 0
          "
          cols="12"
          sm="12"
          lg="12"
          xl="12"
          class="col-bordered"
        >
          <span class="key-title">
            Animais a serem ordenhados por último:
          </span>
          <br />
          <v-chip
            v-for="(ordenhados, index) in relatorio.AnimaisOrdenhadosPorUltimo"
            :key="index"
            class="ma-1"
          >
            <span>
              <strong>Nome ou brinco: </strong>
              {{ ordenhados.animaisOrdenhadosPorUltimoNomeOuBrinco }}<br />
            </span>
          </v-chip>
        </v-col>
        <v-col
          v-if="
            relatorio.AnimaisParaDescarte != null &&
            relatorio.AnimaisParaDescarte.length != 0
          "
          cols="12"
          sm="12"
          lg="12"
          xl="12"
          class="col-bordered"
        >
          <span class="key-title"> Animais para descarte: </span>
          <br />
          <v-chip
            v-for="(descartados, index) in relatorio.AnimaisParaDescarte"
            :key="index"
            class="ma-1"
          >
            <span>
              <strong>Nome ou brinco: </strong>
              {{ descartados.animaisParaDescarteNomeOuBrinco }}<br />
            </span>
          </v-chip>
        </v-col>
        <v-col
          v-if="
            relatorio.AnimaisEQuartosMamariosInativacao != null &&
            relatorio.AnimaisEQuartosMamariosInativacao.length != 0
          "
          cols="12"
          sm="12"
          lg="12"
          xl="12"
          class="col-bordered"
        >
          <span class="key-title">
            Animais e quartos mamários para inativação:
          </span>
          <br />
          <v-chip
            v-for="(
              inativados, index
            ) in relatorio.AnimaisEQuartosMamariosInativacao"
            :key="index"
            class="ma-1"
          >
            <span>
              <strong>Nome ou brinco: </strong>
              {{ inativados.animaisEQuartosMamariosInativacaoNomeOuBrinco }}
              <strong> Quartos mamários: </strong>
              {{ inativados.animaisEQuartosMamariosInativacaoQuartosMamarios }}
              <br />
            </span>
          </v-chip>
        </v-col>
        <v-col
          v-if="
            relatorio.VacinacaoProximoMes != null &&
            relatorio.VacinacaoProximoMes.length != 0
          "
          cols="12"
          sm="12"
          lg="12"
          xl="12"
          class="col-bordered"
        >
          <span class="key-title"> Vacinação para o próximo mês: </span>
          <br />
          <v-chip
            v-for="(vacinas, index) in relatorio.VacinacaoProximoMes"
            :key="index"
            class="ma-1"
          >
            <span>
              <strong>Nome vacina: </strong>
              {{ vacinas.vacinacaoProximoMesNomeVacina }}<br />
            </span>
          </v-chip>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'NutricaoRebanhoCCS',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {},
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
