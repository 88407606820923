<template>
  <v-card class="ma-3">
    <base-loading v-if="loading" />
    <div v-else>
      <v-card-title style="justify-content: center; font-size: 24px">
        <v-row justify="center">
          <v-col cols="12">
            <v-row justify="center"> Relatório de visita CCS </v-row>
          </v-col>
          <v-col cols="12">
            <v-row justify="center">{{ relatorio.NumeroVisita }}ª visita</v-row>
          </v-col>
        </v-row>
      </v-card-title>
      <InformacoesGeraisCCS :relatorio="relatorio" />
      <IndicadoresQualidadeCCS :relatorio="relatorio" />
      <ComposicaoRebanhoCCS :relatorio="relatorio" />
      <AcompanhamentoOrdenhaCCS :relatorio="relatorio" />
      <RecomendacaoOrdenhaCCS :relatorio="relatorio" />
      <BiossegurancaCCS :relatorio="relatorio" />
      <RecomendacaoBiossegurancaCCS :relatorio="relatorio" />
      <NutricaoRebanhoCCS :relatorio="relatorio" />
      <RecomendacaoNutricaoCCS :relatorio="relatorio" />
      <ProtocolosCCS :relatorio="relatorio" />
      <ListagemDeinconformidades :relatorio="relatorio" />
      <RecomendacaoGeraisCCS :relatorio="relatorio" />
      <ImagensAnexoCCS :relatorio="relatorio" />
      <ProximaVisitaCCS :relatorio="relatorio" />
      <v-col cols="12">
        <v-row justify="center">
          <v-btn color="primary" @click="export_PDF">
            <v-icon style="margin-right: 6px"> mdi-file-document </v-icon>
            <span style="font-weight: bold; font-size: 16px">Exportar PDF</span>
          </v-btn>
        </v-row>
      </v-col>
      <v-row justify="center">
        <v-dialog v-model="dialogProgressPDF" persistent max-width="430">
          <v-card>
            <v-card-title class="text-h3"> Aguarde! </v-card-title>
            <v-card-title class="text-h3">
              Estamos preparando seu relatório.
            </v-card-title>
            <v-card-text>
              <div class="text-center">
                <v-progress-circular :size="40" indeterminate color="teal">
                </v-progress-circular>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import exportPDF from '../../../../services/reports/RelatorioPDFCCS'
import { exportReport } from '../../../../utils/report'
import AcompanhamentoOrdenhaCCS from './AcompanhamentoOrdenhaCCS'
import BiossegurancaCCS from './BiossegurancaCCS'
import ComposicaoRebanhoCCS from './ComposicaoRebanhoCCS'
import ImagensAnexoCCS from './ImagensAnexoCCS'
import IndicadoresQualidadeCCS from './IndicadoresQualidadeCCS'
import InformacoesGeraisCCS from './InformacoesGeraisCCS'
import ListagemDeinconformidades from './ListagemDeinconformidades.vue'
import NutricaoRebanhoCCS from './NutricaoRebanhoCCS'
import ProtocolosCCS from './ProtocolosCCS'
import ProximaVisitaCCS from './ProximaVisitaCCS'
import RecomendacaoBiossegurancaCCS from './RecomendacaoBiossegurancaCCS'
import RecomendacaoGeraisCCS from './RecomendacaoGeraisCCS'
import RecomendacaoNutricaoCCS from './RecomendacaoNutricaoCCS'
import RecomendacaoOrdenhaCCS from './RecomendacaoOrdenhaCCS'

export default {
  components: {
    AcompanhamentoOrdenhaCCS,
    RecomendacaoOrdenhaCCS,
    RecomendacaoGeraisCCS,
    RecomendacaoNutricaoCCS,
    ComposicaoRebanhoCCS,
    NutricaoRebanhoCCS,
    IndicadoresQualidadeCCS,
    InformacoesGeraisCCS,
    ProximaVisitaCCS,
    BiossegurancaCCS,
    ProtocolosCCS,
    ImagensAnexoCCS,
    RecomendacaoBiossegurancaCCS,
    ListagemDeinconformidades,
  },

  props: {
    codRelatorio: {
      type: String,
      default: () => '',
    },
    draft: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      dialogProgressPDF: false,
      loading: true,
      relatorio: null,
    }
  },
  mounted() {
    if (!this.codRelatorio) {
      this.$router.push({ path: `/${this.$user.get().role}/relatorios/ccs` })
    } else {
      this.getRelatorio()
    }
  },
  methods: {
    exportPDF,
    getRelatorio() {
      this.api.get.relatorioccs(this.codRelatorio, this.draft).then(data => {
        this.relatorio = data
        this.loading = false
      })
    },

    async exportRel(relatorio) {
      if (!relatorio) relatorio = this.relatorio

      await this.exportPDF(relatorio)
    },
    alert() {
      this.Swal.fire({
        title: 'Atenção',
        text: 'Em Desenvolvimento!!!',
        icon: 'warning',
      })
    },
    async export_PDF() {
      this.dialogProgressPDF = true
      await exportReport({
        report: this.relatorio,
        exportReport: this.exportPDF,
      })
      this.dialogProgressPDF = false
    },
  },
}
</script>
