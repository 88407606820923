/* eslint-disable vue/valid-template-root */
<template>
  <v-card v-if="relatorio.RecomendacoesGerais" class="ma-4">
    <v-card-title style="text-align: left">Recomendações gerais</v-card-title>
    <v-card-text>
      <v-row class="pa-3">
        <v-col cols="12" class="col-bordered">
          <span class="value-title">{{ relatorio.RecomendacoesGerais }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'RecomendacaoGeraisCCS',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
