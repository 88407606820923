<template>
  <v-card
    v-if="
      (relatorio.CulturaMicrobiologica != null &&
        relatorio.CulturaMicrobiologica.length != 0) ||
      (relatorio.ProtocoloParaSecagem &&
        relatorio.ProtocoloParaSecagem.trim() !== '') ||
      (relatorio.AnimaisParaSecagem != null &&
        relatorio.AnimaisParaSecagem.length != 0) ||
      (relatorio.SugestaoDeMedicamentos &&
        relatorio.SugestaoDeMedicamentos.trim() !== '') ||
      relatorio.TratamentoMastiteClinica != ''
    "
    class="ma-4"
  >
    <v-card-title style="text-align: left"
      >Protocolo de tratamento</v-card-title
    >
    <v-card-text>
      <v-row class="pa-3">
        <v-col
          v-if="relatorio.CulturaMicrobiologica"
          cols="12"
          sm="12"
          lg="12"
          xl="12"
          class="col-bordered"
        >
          <span class="key-title">
            Cultura microbiológica (principais patógenos)
          </span>
          <br />
          <v-chip
            v-for="(cultura, index) in relatorio.CulturaMicrobiologica"
            :key="index"
            class="ma-1"
          >
            <span>
              <strong>Nome da bactéria: </strong>
              <i> {{ cultura.culturaMicrobiologicaNomeBacteria }} </i>
              <strong>Quantidade de casos: </strong>
              {{ cultura.culturaMicrobiologicaQtdeCasos }}
              <br />
            </span>
          </v-chip>
        </v-col>
        <v-col
          v-if="relatorio.ProtocoloParaSecagem"
          cols="12"
          sm="12"
          lg="12"
          xl="12"
          class="col-bordered"
        >
          <span class="key-title"> Protocolo de secagem </span>
          <br />
          <span class="value-title">{{ relatorio.ProtocoloParaSecagem }}</span>
        </v-col>
        <v-col
          v-if="relatorio.AnimaisParaSecagem"
          cols="12"
          sm="12"
          lg="12"
          xl="12"
          class="col-bordered"
        >
          <span class="key-title">Animais para secagem: </span>
          <br />
          <v-chip
            v-for="(secagem, index) in relatorio.AnimaisParaSecagem"
            :key="index"
            class="ma-1"
          >
            <span>
              <strong>Nome ou brinco: </strong>
              {{ secagem.animaisParaSecagemNomeOuBrinco }}
              <br />
            </span>
          </v-chip>
        </v-col>
        <v-col
          v-if="relatorio.SugestaoDeMedicamentos"
          cols="12"
          sm="12"
          lg="12"
          xl="12"
          class="col-bordered"
        >
          <span class="key-title"> Sugestão de medicamentos </span>
          <br />
          <span class="value-title">{{
            relatorio.SugestaoDeMedicamentos
          }}</span>
        </v-col>
        <v-col
          v-if="relatorio.TratamentoMastiteClinica"
          cols="12"
          sm="12"
          lg="12"
          xl="12"
          class="col-bordered"
        >
          <span class="key-title"> Tratamento mastite clínica </span>
          <br />
          <span class="value-title">{{
            relatorio.TratamentoMastiteClinica
          }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'ProtocolosCCS',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {},
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
