var render = function render(){var _vm=this,_c=_vm._self._c;return (
    (_vm.relatorio.ImagensInformacoesOrdenha != null &&
      _vm.relatorio.ImagensInformacoesOrdenha.length) ||
    (_vm.relatorio.ImagensInformacoesDeRotinaDeOrdenha != null &&
      _vm.relatorio.ImagensInformacoesDeRotinaDeOrdenha.length) ||
    (_vm.relatorio.ImagensBiosegurancaAmbienteImunidade != null &&
      _vm.relatorio.ImagensBiosegurancaAmbienteImunidade.length) ||
    (_vm.relatorio.ImagensNutricaoDoRebanho != null &&
      _vm.relatorio.ImagensNutricaoDoRebanho.length) ||
    (_vm.relatorio.ImagensProtologoDeTratamento != null &&
      _vm.relatorio.ImagensProtologoDeTratamento.length)
  )?_c('v-card',{staticClass:"ma-4"},[_c('v-card-title',{staticStyle:{"text-align":"left"}},[_vm._v("Imagens em anexo")]),_c('v-card-text',[_c('v-row',{staticClass:"pa-3"},[(
          _vm.relatorio.ImagensInformacoesOrdenha != null &&
          _vm.relatorio.ImagensInformacoesOrdenha.length
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Imagens informações de ordenha")]),_c('br'),_vm._l((_vm.relatorio.ImagensInformacoesOrdenha),function(imagem,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[(imagem.id)?_c('v-img',{attrs:{"src":_vm.getImagem(imagem.id),"contain":"","max-width":"500px","max-height":"500px","alt":"imagem"}}):_vm._e()],1)})],2):_vm._e(),(
          _vm.relatorio.ImagensInformacoesDeRotinaDeOrdenha != null &&
          _vm.relatorio.ImagensInformacoesDeRotinaDeOrdenha.length
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Imagens recomendações - rotina de ordenha")]),_c('br'),_vm._l((_vm.relatorio.ImagensInformacoesDeRotinaDeOrdenha),function(imagem,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[(imagem.id)?_c('v-img',{attrs:{"src":_vm.getImagem(imagem.id),"contain":"","max-width":"500px","max-height":"500px","alt":"imagem"}}):_vm._e()],1)})],2):_vm._e(),(
          _vm.relatorio.ImagensBiosegurancaAmbienteImunidade != null &&
          _vm.relatorio.ImagensBiosegurancaAmbienteImunidade.length
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Imagens biossegurança / ambiente / imunidade")]),_c('br'),_vm._l((_vm.relatorio.ImagensBiosegurancaAmbienteImunidade),function(imagem,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[(imagem.id)?_c('v-img',{attrs:{"src":_vm.getImagem(imagem.id),"contain":"","max-width":"500px","max-height":"500px","alt":"imagem"}}):_vm._e()],1)})],2):_vm._e(),(
          _vm.relatorio.ImagensNutricaoDoRebanho != null &&
          _vm.relatorio.ImagensNutricaoDoRebanho.length
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Imagens nutrição do rebanho")]),_c('br'),_vm._l((_vm.relatorio.ImagensNutricaoDoRebanho),function(imagem,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[(imagem.id)?_c('v-img',{attrs:{"src":_vm.getImagem(imagem.id),"contain":"","max-width":"500px","max-height":"500px","alt":"imagem"}}):_vm._e()],1)})],2):_vm._e(),(
          _vm.relatorio.ImagensProtologoDeTratamento != null &&
          _vm.relatorio.ImagensProtologoDeTratamento.length
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Imagens protocolo de tratamento")]),_c('br'),_vm._l((_vm.relatorio.ImagensProtologoDeTratamento),function(imagem,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[(imagem.id)?_c('v-img',{attrs:{"src":_vm.getImagem(imagem.id),"contain":"","max-width":"500px","max-height":"500px","alt":"imagem"}}):_vm._e()],1)})],2):_vm._e()],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }