import HtmlToCanvas from 'html2canvas'
import JsPDF from 'jspdf'
import moment from 'moment'
import { ENV_APP } from '../../../env'
import { convertNumberBR } from '../../utils/masks'

const utilsRelatorio = {
  getDataAtual() {
    var monName = [
      'janeiro',
      'fevereiro',
      'março',
      'abril',
      'maio',
      'junho',
      'julho',
      'agosto',
      'setembro',
      'outubro',
      'novembro',
      'dezembro',
    ]
    var now = new Date()

    var str =
      'Relatório gerado em ' +
      now.getDate() +
      ' de ' +
      monName[now.getMonth()] +
      ' de ' +
      now.getFullYear() +
      ' às ' +
      formatDate(now, 'HH:mm:ss') +
      '.'
    return str
  },
  calcLacVacas(relatorio) {
    if (relatorio.VacasLactacao && relatorio.VacasSecas) {
      return `${convertNumberBR(
        100 *
          (parseInt(relatorio.VacasLactacao) /
            (parseInt(relatorio.VacasLactacao) +
              parseInt(relatorio.VacasSecas))),
      )} %`
    } else {
      return '-'
    }
  },
  calcLacRebanho(relatorio) {
    var totalRebanho = 0
    if (relatorio.VacasLactacao) {
      totalRebanho += parseInt(relatorio.VacasLactacao)
    }
    if (relatorio.VacasSecas) {
      totalRebanho += parseInt(relatorio.VacasSecas)
    }
    if (relatorio.Novilhas) {
      totalRebanho += parseInt(relatorio.Novilhas)
    }
    if (relatorio.Recria) {
      totalRebanho += parseInt(relatorio.Recria)
    }
    if (relatorio.Aleitamento) {
      totalRebanho += parseInt(relatorio.Aleitamento)
    }
    if (relatorio.Machos) {
      totalRebanho += parseInt(relatorio.Machos)
    }
    if (totalRebanho > 0) {
      return `${convertNumberBR(
        100 * (parseInt(relatorio.VacasLactacao) / totalRebanho),
      )} %`
    } else {
      return '-'
    }
  },
  calcProdutividade(relatorio) {
    if (relatorio.VacasLactacao && relatorio.ProducaoMedia) {
      return `${convertNumberBR(
        parseInt(relatorio.ProducaoMedia) / parseInt(relatorio.VacasLactacao),
      )} L / dia`
    } else {
      return '-'
    }
  },
  calcProdutividadeTotal(relatorio) {
    var totalVacas = 0
    if (relatorio.VacasLactacao) {
      totalVacas += parseInt(relatorio.VacasLactacao)
    }
    if (relatorio.VacasSecas) {
      totalVacas += parseInt(relatorio.VacasSecas)
    }
    if (relatorio.ProducaoMedia && totalVacas > 0) {
      return `${convertNumberBR(
        parseInt(relatorio.ProducaoMedia) / totalVacas,
      )} L / dia`
    } else {
      return '-'
    }
  },
}

function formatDate(date, format = 'DD/MM/YYYY') {
  return moment(date).format(format)
}

function renderCheckbox(doc, text, cursor, config) {
  const {
    smallBoxHeight,
    line,
    smallGutterX,
    smallGutterY,
    startX,
    tableLimit,
  } = config

  doc.rect(startX, cursor, line, smallBoxHeight, 'F')

  doc.text(text, startX + smallGutterX, cursor + smallGutterY)

  doc.rect(startX + tableLimit, cursor, line, smallBoxHeight, 'F')

  doc.rect(startX, cursor + smallBoxHeight, tableLimit, line, 'F')
}

function renderRecomendacoes(doc, text, data, cursor, config) {
  const {
    smallBoxHeight,
    line,
    smallGutterX,
    smallGutterY,
    gutterY,
    startX,
    tableLimit,
  } = config

  if (!data) return cursor

  data = data || '-'
  const width = doc.getTextWidth(data)
  const numberLines = parseInt(width / (tableLimit - smallGutterX * 2) + 2)

  cursor = checkAddPage(doc, cursor, smallBoxHeight * numberLines, config)

  doc.rect(startX, cursor, line, smallBoxHeight * numberLines, 'F')

  doc.setFont('helvetica', 'normal')
  doc.text(text, startX + smallGutterX, cursor + smallGutterY)

  doc.text(data, startX + smallGutterX, cursor + gutterY, {
    maxWidth: tableLimit - smallGutterX * 2,
  })

  doc.rect(startX + tableLimit, cursor, line, smallBoxHeight * numberLines, 'F')

  doc.rect(startX, cursor + smallBoxHeight * numberLines, tableLimit, line, 'F')

  return cursor + smallBoxHeight * numberLines
}

function checkAddPage(doc, cursor, area, config) {
  const { heightLimit, margin, tableLimit, line, startX } = config

  if (cursor + area > heightLimit) {
    doc.rect(startX, cursor, tableLimit, line, 'F')
    doc.addPage()
    cursor = margin
    doc.rect(startX, cursor, tableLimit, line, 'F')
  }

  return cursor
}

function header(doc, config, relatorio) {
  const logo1 = new Image()
  const logo2 = new Image()

  logo1.src = config.logo

  doc.addImage(logo1, 'PNG', config.margin, config.margin, 70, 55)

  if (relatorio.logoagroindustria) {
    logo2.src = relatorio.logoagroindustria
    doc.addImage(logo2, 'PNG', config.tableLimit - 50, config.margin, 73, 50)
  }

  doc.setFontSize(config.largefontSize).setFont('helvetica', 'bold')

  const widthOfString = doc.getTextWidth('Relatório de visita CCS')

  doc.text(
    'Relatório de visita CCS',
    config.tableLimit / 2 - widthOfString / 2 + config.margin,
    config.margin + 30,
  )

  doc.setFontSize(config.fontSize).setFont('helvetica', 'normal')
}

function subHeader(doc, _ref, _ref2) {
  const {
    fazenda: {
      produtor: { Nome: produtor },
      pessoajuridica: { NomeFantasia: fazenda },
      producer: { Matricula: matricula },
    },
    consultor: {
      pessoafisica: { Nome: consultor },
    },
    cidadefazenda,
    DataAtendimento,
    HoraInicio,
    HoraFim,
    NumeroVisita,
    LiderProjeto,
  } = _ref
  const {
    boxHeight,
    gutterY,
    line,
    lineColor,
    smallGutterX,
    smallGutterY,
    startX,
    startY,
    tableLimit,
    mediumFontSize,
    fontSize,
  } = _ref2

  doc.setFillColor(lineColor)

  doc
    .setFont('helvetica', 'bold')
    .setFontSize(mediumFontSize)
    .text('Informações básicas', startX, startY + 10)
    .setFont('helvetica', 'normal')
    .setFontSize(fontSize)

  doc.rect(startX, startY + boxHeight, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight, line, boxHeight, 'F')

  doc.text('Fazenda:', startX + smallGutterX, startY + smallGutterY + boxHeight)

  doc
    .setFont('helvetica', 'bold')
    .text(fazenda, startX + smallGutterX, startY + gutterY + boxHeight)
    .setFont('helvetica', 'normal')

  doc.rect(startX + tableLimit / 2, startY + boxHeight, line, boxHeight, 'F')

  doc.text(
    'Líder de projeto:',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight + smallGutterY,
  )

  doc.text(
    LiderProjeto ? LiderProjeto : '-',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight + gutterY,
  )

  doc.rect(startX, startY + boxHeight * 2, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 2, line, boxHeight, 'F')

  doc.rect(startX + tableLimit, startY + boxHeight, line, boxHeight, 'F')

  doc.text(
    'Produtor(a):',
    startX + smallGutterX,
    startY + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    produtor.substring(0, 25),
    startX + smallGutterX,
    startY + boxHeight * 2 + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight * 2, line, boxHeight, 'F')

  doc.rect(
    startX + tableLimit / 2,
    startY + boxHeight * 2,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Matrícula:',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    matricula ? matricula : '-',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 2 + gutterY,
  )

  doc.rect(startX, startY + boxHeight * 3, tableLimit, line, 'F')

  doc.text(
    'Consultor(a):',
    startX + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )

  doc.text(consultor, startX + smallGutterX, startY + boxHeight * 3 + gutterY)

  doc.rect(
    startX + tableLimit / 2,
    startY + boxHeight * 3,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Cidade:',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )

  doc.text(
    cidadefazenda ? cidadefazenda.Nome : '-',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 3 + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight * 3, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 3, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 4, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 4, line, boxHeight, 'F')

  doc.text(
    'Data:',
    startX + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    DataAtendimento,
    startX + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 4,
    startY + boxHeight * 4,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Horário do início:',
    startX + tableLimit / 4 + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    HoraInicio,
    startX + tableLimit / 4 + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 2,
    startY + boxHeight * 4,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Horário do término:',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    HoraFim,
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(
    startX + tableLimit - tableLimit / 4,
    startY + boxHeight * 4,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Número da visita:',
    startX + tableLimit - tableLimit / 4 + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    `${NumeroVisita}ª visita`,
    startX + tableLimit - tableLimit / 4 + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight * 4, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 5, tableLimit, line, 'F')
}

function indicadoresQualidade(doc, relatorio, _ref2) {
  const {
    boxHeight,
    fontSize,
    line,
    gutterY,
    mediumFontSize,
    smallGutterX,
    smallGutterY,
    startX,
    startY,
    tableLimit,
  } = _ref2

  var TipoOrdenha
  if (relatorio.TipoOrdenha) {
    if (relatorio.TipoOrdenhaManual) TipoOrdenha = 'Manual'
    if (relatorio.TipoOrdenhaBaldeAoPe) TipoOrdenha = 'Balde ao Pé'
    if (relatorio.TipoOrdenhaTransferidor) TipoOrdenha = 'Transferidor'
    if (relatorio.TipoOrdenhaLinhaBaixa)
      TipoOrdenha = 'Circuito fechado / linha baixa'
    if (relatorio.TipoOrdenhaLinhaMedia)
      TipoOrdenha = 'Circuito fechado / linha média'
    if (relatorio.TipoOrdenhaLinhaAlta)
      TipoOrdenha = 'Circuito fechado / linha alta'
  }

  doc
    .setFont('helvetica', 'bold')
    .setFontSize(mediumFontSize)
    .text('Principais Indicadores', startX, startY + 10)
    .setFont('helvetica', 'normal')
    .setFontSize(fontSize)

  doc.rect(startX, startY + boxHeight, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight, line, boxHeight, 'F')

  doc.text(
    'Produção média (l/dia):',
    startX + smallGutterX,
    startY + boxHeight + smallGutterY,
  )

  doc.text(
    relatorio.ProducaoMedia ? `${relatorio.ProducaoMedia}` : 'Não informado',
    startX + smallGutterX,
    startY + boxHeight + gutterY,
  )

  doc.rect(startX + tableLimit / 3, startY + boxHeight, line, boxHeight, 'F')

  doc.text(
    'Proteína média:',
    startX + tableLimit / 3 + smallGutterX,
    startY + boxHeight + smallGutterY,
  )

  doc.text(
    relatorio.ProteinaMediaUltimoMes
      ? `${relatorio.ProteinaMediaUltimoMes} %`
      : 'Não informado',
    startX + tableLimit / 3 + smallGutterX,
    startY + boxHeight + gutterY,
  )

  doc.rect(
    startX + tableLimit - tableLimit / 3,
    startY + boxHeight,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Gordura média:',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + boxHeight + smallGutterY,
  )

  doc.text(
    relatorio.GorduraMediaUltimoMes
      ? relatorio.GorduraMediaUltimoMes + ' %'
      : 'Não informado',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + boxHeight + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 2, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 2, line, boxHeight, 'F')

  doc.text(
    'CPP média:',
    startX + smallGutterX,
    startY + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    relatorio.CppMedia ? `${relatorio.CppMedia} UFC/ml * 10³` : 'Não informado',
    startX + smallGutterX,
    startY + boxHeight * 2 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 2,
    startY + boxHeight * 2,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'CCS média',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    relatorio.CcsMedia
      ? `${relatorio.CcsMedia} céls/ml * 10³`
      : 'Não informado',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 2 + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight * 2, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 3, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 3, line, boxHeight, 'F')

  doc.rect(startX + tableLimit, startY + boxHeight * 3, line, boxHeight, 'F')

  doc.text(
    'Número de casos de mastite clínica:',
    startX + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )

  doc.text(
    relatorio.CasosMastiteClinica
      ? relatorio.CasosMastiteClinica
      : 'Não informado',
    startX + smallGutterX,
    startY + boxHeight * 3 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 2,
    startY + boxHeight * 3,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Taxa de mastite crônica:',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )

  doc.text(
    relatorio.taxaMastiteCronica
      ? relatorio.taxaMastiteCronica + '%'
      : 'Não informado',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 3 + gutterY,
  )

  doc.rect(startX, startY + boxHeight * 4, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 4, line, boxHeight, 'F')

  doc.rect(startX + tableLimit, startY + boxHeight * 4, line, boxHeight, 'F')

  doc.text(
    'Taxa de cura: ',
    startX + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    relatorio.TaxaDeCura ? relatorio.TaxaDeCura + '%' : 'Não informado',
    startX + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 2,
    startY + boxHeight * 4,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Taxa de novas infecções:',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    relatorio.TaxaDeNovasInfeccoes
      ? relatorio.TaxaDeNovasInfeccoes + '%'
      : 'Não informado',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(startX, startY + boxHeight * 5, tableLimit, line, 'F') //superior

  doc.rect(startX, startY + boxHeight * 5, line, boxHeight, 'F') //esquerda

  doc.rect(startX + tableLimit, startY + boxHeight * 5, line, boxHeight, 'F') //direita

  doc.rect(startX, startY + boxHeight * 6, tableLimit, line, 'F') //inferior

  doc.text(
    'Tipo de ordenha:',
    startX + smallGutterX,
    startY + boxHeight * 5 + smallGutterY,
  )

  doc.text(
    TipoOrdenha ? TipoOrdenha : 'Não informado',
    startX + smallGutterX,
    startY + boxHeight * 5 + gutterY,
  )
}

function composicaoRebanho(doc, relatorio, config, cursor) {
  const {
    boxHeight,
    fontSize,
    line,
    gutterY,
    mediumFontSize,
    smallGutterX,
    smallGutterY,
    startX,
    startY,
    tableLimit,
  } = config

  cursor = startY + boxHeight + 180

  cursor = checkAddPage(doc, cursor, boxHeight, config)

  doc
    .setFont('helvetica', 'bold')
    .setFontSize(mediumFontSize)
    .text('Composição do rebanho', startX, startY + 10)
    .setFont('helvetica', 'normal')
    .setFontSize(fontSize)

  doc.rect(startX, cursor + boxHeight, tableLimit, line, 'F')

  doc.rect(startX, cursor + boxHeight, line, boxHeight, 'F')

  doc.text(
    'Vacas em lactação:',
    startX + smallGutterX,
    cursor + boxHeight + smallGutterY,
  )

  doc.text(
    `${relatorio.VacasLactacao}`,
    startX + smallGutterX,
    cursor + boxHeight + gutterY,
  )

  doc.rect(startX + tableLimit / 3, cursor + boxHeight, line, boxHeight, 'F')

  doc.text(
    'Vacas secas:',
    startX + tableLimit / 3 + smallGutterX,
    cursor + boxHeight + smallGutterY,
  )

  doc.text(
    `${relatorio.VacasSecas}`,
    startX + tableLimit / 3 + smallGutterX,
    cursor + boxHeight + gutterY,
  )

  doc.rect(
    startX + tableLimit - tableLimit / 3,
    cursor + boxHeight,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Novilhas:',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    cursor + boxHeight + smallGutterY,
  )

  doc.text(
    `${relatorio.Novilhas}`,
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    cursor + boxHeight + gutterY,
  )

  doc.rect(startX + tableLimit, cursor + boxHeight, line, boxHeight, 'F')

  doc.rect(startX, cursor + boxHeight * 2, tableLimit, line, 'F')

  doc.rect(startX, cursor + boxHeight * 2, line, boxHeight, 'F')

  doc.text(
    'Machos:',
    startX + smallGutterX,
    cursor + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    `${relatorio.Machos}`,
    startX + smallGutterX,
    cursor + boxHeight * 2 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 3,
    cursor + boxHeight * 2,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Animais em recria:',
    startX + tableLimit / 3 + smallGutterX,
    cursor + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    `${relatorio.Recria}`,
    startX + tableLimit / 3 + smallGutterX,
    cursor + boxHeight * 2 + gutterY,
  )

  doc.rect(
    startX + tableLimit - tableLimit / 3,
    cursor + boxHeight * 2,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Animais em aleitamento:',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    cursor + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    `${relatorio.Aleitamento}`,
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    cursor + boxHeight * 2 + gutterY,
  )

  doc.rect(startX + tableLimit, cursor + boxHeight * 2, line, boxHeight, 'F')

  doc.rect(startX, cursor + boxHeight * 3, tableLimit, line, 'F')

  doc.rect(startX, cursor + boxHeight * 3, line, boxHeight, 'F')

  doc.text(
    'Vacas em lactação / total de vacas:',
    startX + smallGutterX,
    cursor + boxHeight * 3 + smallGutterY,
  )

  doc.text(
    `${utilsRelatorio.calcLacVacas(relatorio)}`,
    startX + smallGutterX,
    cursor + boxHeight * 3 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 2,
    cursor + boxHeight * 3,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Vacas em lactação / total do rebanho:',
    startX + tableLimit / 2 + smallGutterX,
    cursor + boxHeight * 3 + smallGutterY,
  )

  doc.text(
    `${utilsRelatorio.calcLacRebanho(relatorio)}`,
    startX + tableLimit / 2 + smallGutterX,
    cursor + boxHeight * 3 + gutterY,
  )

  doc.rect(startX + tableLimit, cursor + boxHeight * 3, line, boxHeight, 'F')

  doc.rect(startX, cursor + boxHeight * 4, tableLimit, line, 'F')

  doc.rect(startX, cursor + boxHeight * 4, line, boxHeight, 'F')

  doc.text(
    'Produtividade / vacas em lactação:',
    startX + smallGutterX,
    cursor + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    `${utilsRelatorio.calcProdutividade(relatorio)}`,
    startX + smallGutterX,
    cursor + boxHeight * 4 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 2,
    cursor + boxHeight * 4,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Produtividade / total de vacas:',
    startX + tableLimit / 2 + smallGutterX,
    cursor + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    `${utilsRelatorio.calcProdutividadeTotal(relatorio)}`,
    startX + tableLimit / 2 + smallGutterX,
    cursor + boxHeight * 4 + gutterY,
  )

  doc.rect(startX + tableLimit, cursor + boxHeight * 4, line, boxHeight, 'F')

  doc.rect(startX, cursor + boxHeight * 5, tableLimit, line, 'F')

  cursor += cursor + boxHeight

  return cursor + boxHeight
}

function acompanhamentoOrdenha(doc, relatorio, config, cursor) {
  const {
    boxHeight,
    line,
    gutterY,
    mediumFontSize,
    smallGutterX,
    smallGutterY,
    startX,
    tableLimit,
    heightLimit,
    margin,
    fontSize,
    smallBoxHeight,
  } = config
  if (
    relatorio.VacasHiperqueratose ||
    relatorio.CasosSobreordenha ||
    relatorio.VacasEscoreLimpeza ||
    relatorio.VacasSujas ||
    relatorio.TempoMedioPreparo ||
    relatorio.TempoMedioOrdenha
  ) {
    //cursor += config.boxHeight

    if (cursor + boxHeight * 4 > heightLimit) {
      doc.addPage()
      cursor = margin
    }

    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Informações de ordenha', startX, cursor)
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    cursor += smallBoxHeight - 30

    doc.rect(startX, cursor + boxHeight, tableLimit, line, 'F')
    doc.rect(startX, cursor + boxHeight, line, boxHeight, 'F')

    doc.text(
      'Número de vacas com hiperqueratose de teto:',
      startX + smallGutterX,
      cursor + boxHeight + smallGutterY,
    )

    doc.text(
      `${relatorio.VacasHiperqueratose || '-'}`,
      startX + smallGutterX,
      cursor + boxHeight + gutterY,
    )

    cursor = checkAddPage(doc, cursor, boxHeight, config)

    doc.rect(startX + tableLimit / 2, cursor + boxHeight, line, boxHeight, 'F')

    doc.text(
      'Número de casos de sobreordenha:',
      startX + tableLimit / 2 + smallGutterX,
      cursor + boxHeight + smallGutterY,
    )

    doc.text(
      `${relatorio.CasosSobreordenha || '-'}`,
      startX + tableLimit / 2 + smallGutterX,
      cursor + boxHeight + gutterY,
    )

    cursor = checkAddPage(doc, cursor, boxHeight, config)

    doc.rect(startX + tableLimit, cursor + boxHeight, line, boxHeight, 'F')

    doc.rect(startX, cursor + boxHeight * 2, tableLimit, line, 'F')

    doc.rect(startX, cursor + boxHeight * 2, line, boxHeight, 'F')

    doc.text(
      'Número de vacas escore de limpeza da ponta do teto 3 e 4:',
      startX + smallGutterX,
      cursor + boxHeight * 2 + smallGutterY,
    )

    doc.text(
      `${relatorio.VacasEscoreLimpeza || '-'}`,
      startX + smallGutterX,
      cursor + boxHeight * 2 + gutterY,
    )

    cursor = checkAddPage(doc, cursor, boxHeight, config)

    doc.rect(
      startX + tableLimit / 2,
      cursor + boxHeight * 2,
      line,
      boxHeight,
      'F',
    )

    doc.text(
      'Número de vacas sujas (escore de sujidade 3 e 4):',
      startX + tableLimit / 2 + smallGutterX,
      cursor + boxHeight * 2 + smallGutterY,
    )

    cursor = checkAddPage(doc, cursor, boxHeight, config)

    doc.text(
      `${relatorio.VacasSujas || '-'}`,
      startX + tableLimit / 2 + smallGutterX,
      cursor + boxHeight * 2 + gutterY,
    )

    doc.rect(
      startX + tableLimit / 2,
      cursor + boxHeight * 3,
      line,
      boxHeight,
      'F',
    )

    cursor = checkAddPage(doc, cursor, boxHeight, config)

    doc.text(
      'Tempo médio de preparo:',
      startX + smallGutterX,
      cursor + boxHeight * 3 + smallGutterY,
    )

    doc.text(
      `${relatorio.TempoMedioPreparo || '-'}`,
      startX + smallGutterX,
      cursor + boxHeight * 3 + gutterY,
    )

    cursor = checkAddPage(doc, cursor, boxHeight, config)

    doc.text(
      'Tempo médio de ordenha: ',
      startX + tableLimit / 2 + smallGutterX,
      cursor + boxHeight * 3 + smallGutterY,
    )

    doc.text(
      `${relatorio.TempoMedioOrdenha || '-'}`,
      startX + tableLimit / 2 + smallGutterX,
      cursor + boxHeight * 3 + gutterY,
    )
    cursor = checkAddPage(doc, cursor, boxHeight, config)

    doc.rect(startX + tableLimit, cursor + boxHeight * 2, line, boxHeight, 'F')

    doc.rect(startX, cursor + boxHeight * 3, tableLimit, line, 'F')

    doc.rect(startX, cursor + boxHeight * 3, line, boxHeight, 'F')

    doc.rect(startX + tableLimit, cursor + boxHeight * 3, line, boxHeight, 'F')

    doc.rect(startX, cursor + boxHeight * 4, tableLimit, line, 'F')
  }

  return cursor + boxHeight * 4
}

function recomendacaoOrdenha(doc, relatorio, config, cursor) {
  const {
    smallBoxHeight,
    boxHeight,
    line,
    mediumFontSize,
    fontSize,
    startX,
    tableLimit,
    heightLimit,
    margin,
  } = config

  if (
    (relatorio.Ordenha &&
      (relatorio.RealizarLinhaOrdenha ||
        relatorio.TesteCaneca ||
        relatorio.PreDippingOrdenha ||
        (relatorio.PapelToalha && relatorio.PapelToalha == 1) ||
        relatorio.PosDipping ||
        relatorio.ComprarTermometro ||
        relatorio.ProcedimentoOrdenha ||
        relatorio.ColetaLeiteCCS ||
        relatorio.ColetaLeiteMicrobiologica ||
        relatorio.UtilizarAntibioticoSecas ||
        relatorio.UtilizarSelanteIntramamario ||
        relatorio.ManterAreaDescanso ||
        relatorio.CercarAreaDescanso ||
        relatorio.RecomendacaoOrdenha)) ||
    relatorio.OrdenhaObs
  ) {
    cursor += config.boxHeight
    if (cursor + boxHeight * 2 > heightLimit) {
      doc.addPage()
      cursor = margin
    }

    if (relatorio.Ordenha) {
      doc
        .setFont('helvetica', 'bold')
        .setFontSize(mediumFontSize)
        .text('Recomendações - Rotina de ordenha', startX, cursor)
        .setFont('helvetica', 'normal')
        .setFontSize(fontSize)

      cursor += smallBoxHeight - 5

      doc.rect(startX, cursor, tableLimit, line, 'F')

      if (relatorio.RealizarLinhaOrdenha) {
        doc.rect(startX, cursor, tableLimit, line, 'F')
        renderCheckbox(
          doc,
          'Realizar linha de ordenha proposta em anexo',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.TesteCaneca) {
        renderCheckbox(
          doc,
          'Fazer o teste da caneca em todas as ordenhas',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.PreDippingOrdenha && relatorio.PreDippingOrdenha == 1) {
        renderCheckbox(
          doc,
          'Realizar a aplicação do pré dipping em todas as vacas',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.PapelToalha) {
        renderCheckbox(
          doc,
          'Utilizar papel toalha para secagem dos tetos',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.PosDipping) {
        renderCheckbox(
          doc,
          'Realizar a aplicação do pós dipping em todas as vacas',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.ComprarTermometro) {
        renderCheckbox(
          doc,
          'Comprar termômetro para aferição da temperatura da água (70°C)',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.ProcedimentoOrdenha) {
        renderCheckbox(
          doc,
          'Seguir procedimentos de ordenha conforme procedimento operacional padrão em anexo',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.ColetaLeiteCCS) {
        renderCheckbox(
          doc,
          'Realizar coleta de leite para análise individual da CCS',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.ColetaLeiteMicrobiologica) {
        renderCheckbox(
          doc,
          'Realizar coleta de leite para análise microbiológica do leite',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.UtilizarAntibioticoSecas) {
        renderCheckbox(
          doc,
          'Utilizar antibiótico vaca seca para secagem das vacas (indicação do produto)',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.UtilizarSelanteIntramamario) {
        renderCheckbox(
          doc,
          'Utilizar selante intramamário para secagem das vacas (indicação do produto)',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.ManterAreaDescanso) {
        renderCheckbox(
          doc,
          'Manter limpas as áreas de descanso das vacas',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.CercarAreaDescanso) {
        renderCheckbox(
          doc,
          'Cercar as áreas de descanso com acúmulo de barro',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.RecomendacaoOrdenha) {
        cursor = renderRecomendacoes(
          doc,
          'Outras recomendações:',
          relatorio.RecomendacaoOrdenha,
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.OrdenhaObs) {
        cursor = renderRecomendacoes(
          doc,
          'Atividade não realizada',
          relatorio.OrdenhaObs,
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
    }
  }

  return cursor + smallBoxHeight
}

function biosseguranca(doc, relatorio, config, cursor) {
  const {
    smallBoxHeight,
    boxHeight,
    fontSize,
    line,
    gutterY,
    mediumFontSize,
    smallGutterX,
    smallGutterY,
    startX,
    tableLimit,
    heightLimit,
    margin,
  } = config

  if (
    (relatorio.AnimaisOrdenhadosPorUltimo != null &&
      relatorio.AnimaisOrdenhadosPorUltimo.length != 0) ||
    (relatorio.AnimaisParaDescarte != null &&
      relatorio.AnimaisParaDescarte.length != 0) ||
    (relatorio.AnimaisEQuartosMamariosInativacao != null &&
      relatorio.AnimaisEQuartosMamariosInativacao.length != 0) ||
    (relatorio.VacinacaoProximoMes != null &&
      relatorio.VacinacaoProximoMes.length != 0) ||
    relatorio.QtdeAnimaisComClaudLactacao != '' ||
    relatorio.QtdeAnimaisComClaudSecas != '' ||
    relatorio.QtdeAnimaisClaudicacao != '' ||
    relatorio.QtdeAnimaisEstresse != '' ||
    relatorio.TaxaRetencaoPlacenta != '' ||
    relatorio.QtdeAnimaisComEstresseCalorico != '' ||
    (relatorio.OutrasRecomendacoesBiosseguranca &&
      relatorio.OutrasRecomendacoesBiosseguranca.trim() !== '')
  ) {
    cursor += config.boxHeight

    if (cursor + boxHeight * 4 > heightLimit) {
      doc.addPage()
      cursor = margin + 10
    }

    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Biossegurança / Ambiente / Imunidade', startX, cursor)
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    cursor += smallBoxHeight - 5

    if (relatorio.QtdeAnimaisClaudicacao || relatorio.QtdeAnimaisEstresse) {
      doc.rect(startX, cursor, tableLimit / 2, line, 'F') // superior
      doc.rect(startX, cursor, line, boxHeight, 'F') // esquerda
      doc.rect(tableLimit / 2 + startX, cursor, line, boxHeight, 'F') // direita
      doc.rect(startX, cursor + boxHeight, tableLimit / 2, line, 'F') // inferior

      doc.text(
        'Número de animais com claudicação',
        startX + smallGutterX,
        cursor + smallGutterY,
      )

      doc.text(
        `${relatorio.QtdeAnimaisClaudicacao}` || '-',
        startX + smallGutterX,
        cursor + gutterY,
      )

      doc.rect(tableLimit / 2 + startX, cursor, tableLimit / 2, line, 'F') // superior
      doc.rect(tableLimit / 2 + startX, cursor, line, boxHeight, 'F') // esquerda
      doc.rect(tableLimit + startX, cursor, line, boxHeight, 'F') // direita
      doc.rect(
        tableLimit / 2 + startX,
        cursor + boxHeight,
        tableLimit / 2,
        line,
        'F',
      ) // inferior

      doc.text(
        'Número de animais com estresse calórico',
        startX + tableLimit / 2 + smallGutterX,
        cursor + smallGutterY,
      )

      doc.text(
        `${relatorio.QtdeAnimaisEstresse}` || '-',
        startX + tableLimit / 2 + smallGutterX,
        cursor + gutterY,
      )

      cursor += boxHeight
      cursor = checkAddPage(doc, cursor, boxHeight, config)
    }

    if (
      relatorio.QtdeAnimaisComClaudLactacao ||
      relatorio.QtdeAnimaisComClaudSecas
    ) {
      doc.rect(startX, cursor, tableLimit / 2, line, 'F') // superior
      doc.rect(startX, cursor, line, boxHeight, 'F') // esquerda
      doc.rect(tableLimit / 2 + startX, cursor, line, boxHeight, 'F') // direita
      doc.rect(startX, cursor + boxHeight, tableLimit / 2, line, 'F') // inferior

      doc.text(
        'Taxa de claudicação das vacas em lactação',
        startX + smallGutterX,
        cursor + smallGutterY,
      )

      doc.text(
        `${relatorio.QtdeAnimaisComClaudLactacao + '%'}` || '-',
        startX + smallGutterX,
        cursor + gutterY,
      )

      doc.rect(tableLimit / 2 + startX, cursor, tableLimit / 2, line, 'F') // superior
      doc.rect(tableLimit / 2 + startX, cursor, line, boxHeight, 'F') // esquerda
      doc.rect(tableLimit + startX, cursor, line, boxHeight, 'F') // direita
      doc.rect(
        tableLimit / 2 + startX,
        cursor + boxHeight,
        tableLimit / 2,
        line,
        'F',
      ) // inferior

      doc.text(
        'Taxa de claudicação das vacas secas',
        startX + tableLimit / 2 + smallGutterX,
        cursor + smallGutterY,
      )

      doc.text(
        `${relatorio.QtdeAnimaisComClaudSecas + '%'}` || '-',
        startX + tableLimit / 2 + smallGutterX,
        cursor + gutterY,
      )

      cursor += boxHeight
      cursor = checkAddPage(doc, cursor, boxHeight, config)
    }

    if (
      relatorio.TaxaRetencaoPlacenta ||
      relatorio.QtdeAnimaisComEstresseCalorico
    ) {
      if (relatorio.TaxaRetencaoPlacenta) {
        doc.rect(startX, cursor, tableLimit / 2, line, 'F') // superior
        doc.rect(startX, cursor, line, boxHeight, 'F') // esquerda
        doc.rect(tableLimit / 2 + startX, cursor, line, boxHeight, 'F') // direita
        doc.rect(startX, cursor + boxHeight, tableLimit / 2, line, 'F') // inferior

        doc.text(
          'Taxa de retenção de placenta',
          startX + smallGutterX,
          cursor + smallGutterY,
        )

        doc.text(
          `${relatorio.TaxaRetencaoPlacenta + '%'}`,
          startX + smallGutterX,
          cursor + gutterY,
        )
      }

      if (relatorio.QtdeAnimaisComEstresseCalorico) {
        doc.rect(tableLimit / 2 + startX, cursor, tableLimit / 2, line, 'F') // superior
        doc.rect(tableLimit / 2 + startX, cursor, line, boxHeight, 'F') // esquerda
        doc.rect(tableLimit + startX, cursor, line, boxHeight, 'F') // direita
        doc.rect(
          tableLimit / 2 + startX,
          cursor + boxHeight,
          tableLimit / 2,
          line,
          'F',
        ) // inferior

        doc.text(
          'Taxa de animais com estresse calórico',
          startX + tableLimit - tableLimit / 2 + smallGutterX,
          cursor + smallGutterY,
        )

        doc.text(
          `${relatorio.QtdeAnimaisComEstresseCalorico + '%'}`,
          startX + tableLimit - tableLimit / 2 + smallGutterX,
          cursor + gutterY,
        )
      }

      cursor += boxHeight
      cursor = checkAddPage(doc, cursor, boxHeight, config)
    }

    var colInicio = startX

    if (
      relatorio.AnimaisOrdenhadosPorUltimo != null &&
      relatorio.AnimaisOrdenhadosPorUltimo.length != 0
    ) {
      doc.rect(startX, cursor, tableLimit, line, 'F') // superior
      doc.rect(startX, cursor, line, boxHeight, 'F') // esquerda
      doc.rect(startX + tableLimit, cursor, line, boxHeight, 'F') // direita

      doc.text(
        'Animais a serem ordenhados por último',
        startX + tableLimit / 1000 + smallGutterX,
        cursor + smallGutterY,
      )

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, boxHeight, config)

      Object.keys(relatorio.AnimaisOrdenhadosPorUltimo).forEach(item => {
        if (item % 3 === 1) {
          colInicio = startX + tableLimit / 3
        } else if (item % 3 === 2) {
          colInicio = startX + tableLimit - tableLimit / 3
        } else {
          colInicio = startX
        }

        doc.text(
          'Nome ou brinco: ' +
            relatorio.AnimaisOrdenhadosPorUltimo[
              item
            ].animaisOrdenhadosPorUltimoNomeOuBrinco.substr(0, 17),
          colInicio + smallGutterX,
          cursor + smallGutterY,
        )

        if (
          item % 3 === 2 ||
          Object.keys(relatorio.AnimaisOrdenhadosPorUltimo).length - 1 == item
        ) {
          doc.rect(startX, cursor, line, smallBoxHeight, 'F') // esquerda
          doc.rect(tableLimit + startX, cursor, line, smallBoxHeight, 'F') // direita
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }
      })

      doc.rect(startX, cursor, tableLimit, line, 'F') // superior
    }

    if (
      relatorio.AnimaisParaDescarte != null &&
      relatorio.AnimaisParaDescarte.length != 0
    ) {
      doc.rect(startX, cursor, line, smallBoxHeight, 'F') // esquerda
      doc.rect(startX + tableLimit, cursor, line, smallBoxHeight, 'F') // direita

      doc.text(
        'Animais para descarte:',
        startX + tableLimit / 1000 + smallGutterX,
        cursor + smallGutterY,
      )

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      colInicio = startX

      Object.keys(relatorio.AnimaisParaDescarte).forEach(item => {
        if (item % 3 === 1) {
          colInicio = startX + tableLimit / 3
        } else if (item % 3 === 2) {
          colInicio = startX + tableLimit - tableLimit / 3
        } else {
          colInicio = startX
        }

        doc.text(
          'Nome ou brinco: ' +
            relatorio.AnimaisParaDescarte[
              item
            ].animaisParaDescarteNomeOuBrinco.substr(0, 17),
          colInicio + smallGutterX,
          cursor + smallGutterY,
        )

        if (
          item % 3 === 2 ||
          Object.keys(relatorio.AnimaisParaDescarte).length - 1 == item
        ) {
          doc.rect(startX, cursor, line, smallBoxHeight, 'F') // esquerda
          doc.rect(tableLimit + startX, cursor, line, smallBoxHeight, 'F') // direita
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }
      })

      doc.rect(startX, cursor, tableLimit, line, 'F') // superior
    }

    if (
      relatorio.AnimaisEQuartosMamariosInativacao != null &&
      relatorio.AnimaisEQuartosMamariosInativacao.length != 0
    ) {
      doc.rect(startX, cursor, line, smallBoxHeight, 'F') // esquerda
      doc.rect(startX + tableLimit, cursor, line, smallBoxHeight, 'F') // direita

      doc.text(
        'Animais e quartos mamários para inativação',
        startX + tableLimit / 1000 + smallGutterX,
        cursor + smallGutterY,
      )

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      colInicio = startX

      Object.keys(relatorio.AnimaisEQuartosMamariosInativacao).forEach(item => {
        doc.text(
          'Nome ou brinco: ' +
            relatorio.AnimaisEQuartosMamariosInativacao[
              item
            ].animaisEQuartosMamariosInativacaoNomeOuBrinco.substr(0, 17) +
            ' | Quartos mamários: ' +
            relatorio.AnimaisEQuartosMamariosInativacao[item]
              .animaisEQuartosMamariosInativacaoQuartosMamarios,
          colInicio + smallGutterX,
          cursor + smallGutterY,
        )

        doc.rect(startX, cursor, line, smallBoxHeight, 'F') // esquerda
        doc.rect(tableLimit + startX, cursor, line, smallBoxHeight, 'F') // direita
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      })

      doc.rect(startX, cursor, tableLimit, line, 'F') // superior
    }

    if (
      relatorio.VacinacaoProximoMes != null &&
      relatorio.VacinacaoProximoMes.length != 0
    ) {
      doc.rect(startX, cursor, line, smallBoxHeight, 'F') // esquerda
      doc.rect(startX + tableLimit, cursor, line, smallBoxHeight, 'F') // direita

      doc.text(
        'Vacinação para o próximo mês',
        startX + tableLimit / 1000 + smallGutterX,
        cursor + smallGutterY,
      )

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      colInicio = startX

      Object.keys(relatorio.VacinacaoProximoMes).forEach(item => {
        if (item % 3 === 1) {
          colInicio = startX + tableLimit / 3
        } else if (item % 3 === 2) {
          colInicio = startX + tableLimit - tableLimit / 3
        } else {
          colInicio = startX
        }

        doc.text(
          'Nome vacina: ' +
            relatorio.VacinacaoProximoMes[
              item
            ].vacinacaoProximoMesNomeVacina.substr(0, 17),
          colInicio + smallGutterX,
          cursor + smallGutterY,
        )

        if (
          item % 3 === 2 ||
          Object.keys(relatorio.VacinacaoProximoMes).length - 1 == item
        ) {
          doc.rect(startX, cursor, line, smallBoxHeight, 'F') // esquerda
          doc.rect(tableLimit + startX, cursor, line, smallBoxHeight, 'F') // direita
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }
      })

      doc.rect(startX, cursor, tableLimit, line, 'F') // superior
    }

    if (
      relatorio.OutrasRecomendacoesBiosseguranca &&
      relatorio.OutrasRecomendacoesBiosseguranca.trim() !== ''
    ) {
      cursor = checkAddPage(doc, cursor, smallBoxHeight * 2, config)
      doc.rect(startX, cursor, line, smallBoxHeight, 'F') // esquerda
      doc.rect(startX + tableLimit, cursor, line, smallBoxHeight, 'F') // direita

      doc.text(
        'Recomendações - Biossegurança / Ambiente / Imunidade',
        startX + tableLimit / 1000 + smallGutterX,
        cursor + smallGutterY,
      )

      cursor = renderRecomendacoes(
        doc,
        '',
        relatorio.OutrasRecomendacoesBiosseguranca,
        cursor,
        config,
      )

      if (cursor + boxHeight * 2 > heightLimit) {
        doc.addPage()
        cursor = margin
      }
    }
  }

  return cursor
}

function nutricaoRebanho(doc, relatorio, config, cursor) {
  const {
    smallBoxHeight,
    boxHeight,
    fontSize,
    line,
    gutterY,
    mediumFontSize,
    smallGutterX,
    smallGutterY,
    startX,
    tableLimit,
    heightLimit,
    margin,
  } = config
  cursor += config.boxHeight

  if (cursor + boxHeight * 4 > heightLimit) {
    doc.addPage()
    cursor = margin
  }

  if (
    relatorio.EscoreCorporalVacasSecas.trim() !== '' ||
    relatorio.EscoreCorporalVacasPreParto.trim() !== '' ||
    relatorio.EscoreCorporalVacasRecemParidas.trim() !== '' ||
    relatorio.DelMedioRebanho.trim() !== '' ||
    (relatorio.OutrasRecomendacoesNutricaoRebanho &&
      relatorio.OutrasRecomendacoesNutricaoRebanho.trim() !== '')
  ) {
    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Nutrição do rebanho', startX, cursor)
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    cursor += smallBoxHeight - 5

    if (
      relatorio.EscoreCorporalVacasSecas.trim() !== '' ||
      relatorio.DietaVacasLactacaoCondizenteComProducao.trim() !== ''
    ) {
      doc.rect(startX, cursor, tableLimit / 2, line, 'F') // superior
      doc.rect(startX, cursor, line, boxHeight, 'F') // esquerda
      doc.rect(tableLimit / 2 + startX, cursor, line, boxHeight, 'F') // direita
      doc.rect(startX, cursor + boxHeight, tableLimit / 2, line, 'F') // inferior

      doc.text(
        'Escore corporal vacas secas',
        startX + smallGutterX,
        cursor + smallGutterY,
      )

      doc.text(
        `${relatorio.EscoreCorporalVacasSecas}` || '-',
        startX + smallGutterX,
        cursor + gutterY,
      )

      doc.rect(tableLimit / 2 + startX, cursor, tableLimit / 2, line, 'F') // superior
      doc.rect(tableLimit / 2 + startX, cursor, line, boxHeight, 'F') // esquerda
      doc.rect(tableLimit + startX, cursor, line, boxHeight, 'F') // direita
      doc.rect(
        tableLimit / 2 + startX,
        cursor + boxHeight,
        tableLimit / 2,
        line,
        'F',
      ) // inferior

      doc.text(
        'Dieta das vacas em lactação condizente com produção?',
        startX + tableLimit / 2 + smallGutterX,
        cursor + smallGutterY,
      )

      doc.text(
        `${relatorio.DietaVacasLactacaoCondizenteComProducao}` || '-',
        startX + tableLimit / 2 + smallGutterX,
        cursor + gutterY,
      )

      cursor += boxHeight
      cursor = checkAddPage(doc, cursor, boxHeight, config)
    }

    if (
      relatorio.EscoreCorporalVacasPreParto.trim() !== '' ||
      relatorio.ProdutividadeCondizenteComPotencialProdutivo.trim() !== ''
    ) {
      if (relatorio.EscoreCorporalVacasPreParto.trim() !== '') {
        doc.rect(startX, cursor, tableLimit / 2, line, 'F') // superior
        doc.rect(startX, cursor, line, boxHeight, 'F') // esquerda
        doc.rect(tableLimit / 2 + startX, cursor, line, boxHeight, 'F') // direita
        doc.rect(startX, cursor + boxHeight, tableLimit / 2, line, 'F') // inferior

        doc.text(
          'Escore corporal vacas pré parto',
          startX + smallGutterX,
          cursor + smallGutterY,
        )

        doc.text(
          `${relatorio.EscoreCorporalVacasPreParto}`,
          startX + smallGutterX,
          cursor + gutterY,
        )
      }

      if (
        relatorio.ProdutividadeCondizenteComPotencialProdutivo.trim() !== ''
      ) {
        doc.rect(tableLimit / 2 + startX, cursor, tableLimit / 2, line, 'F') // superior
        doc.rect(tableLimit / 2 + startX, cursor, line, boxHeight, 'F') // esquerda
        doc.rect(tableLimit + startX, cursor, line, boxHeight, 'F') // direita
        doc.rect(
          tableLimit / 2 + startX,
          cursor + boxHeight,
          tableLimit / 2,
          line,
          'F',
        ) // inferior

        doc.text(
          'Produtividade das vacas condiz com potencial produtivo?',
          startX + tableLimit - tableLimit / 2 + smallGutterX,
          cursor + smallGutterY,
        )

        doc.text(
          `${relatorio.ProdutividadeCondizenteComPotencialProdutivo}`,
          startX + tableLimit - tableLimit / 2 + smallGutterX,
          cursor + gutterY,
        )
      }

      cursor += boxHeight
      cursor = checkAddPage(doc, cursor, boxHeight, config)
    }

    if (
      relatorio.EscoreCorporalVacasRecemParidas.trim() !== '' ||
      relatorio.DietaPrePartoAnionica.trim() !== ''
    ) {
      if (relatorio.EscoreCorporalVacasRecemParidas.trim() !== '') {
        doc.rect(startX, cursor, tableLimit / 2, line, 'F') // superior
        doc.rect(startX, cursor, line, boxHeight, 'F') // esquerda
        doc.rect(tableLimit / 2 + startX, cursor, line, boxHeight, 'F') // direita
        doc.rect(startX, cursor + boxHeight, tableLimit / 2, line, 'F') // inferior

        doc.text(
          'Escore corporal vacas recém paridas',
          startX + smallGutterX,
          cursor + smallGutterY,
        )

        doc.text(
          `${relatorio.EscoreCorporalVacasRecemParidas}`,
          startX + smallGutterX,
          cursor + gutterY,
        )
      }

      if (relatorio.DietaPrePartoAnionica.trim() !== '') {
        doc.rect(tableLimit / 2 + startX, cursor, tableLimit / 2, line, 'F') // superior
        doc.rect(tableLimit / 2 + startX, cursor, line, boxHeight, 'F') // esquerda
        doc.rect(tableLimit + startX, cursor, line, boxHeight, 'F') // direita
        doc.rect(
          tableLimit / 2 + startX,
          cursor + boxHeight,
          tableLimit / 2,
          line,
          'F',
        ) // inferior

        doc.text(
          'Tipo de dieta no pré-parto é aniônica?',
          startX + tableLimit - tableLimit / 2 + smallGutterX,
          cursor + smallGutterY,
        )

        doc.text(
          `${relatorio.DietaPrePartoAnionica}`,
          startX + tableLimit - tableLimit / 2 + smallGutterX,
          cursor + gutterY,
        )
      }

      cursor += boxHeight
      cursor = checkAddPage(doc, cursor, boxHeight, config)
    }

    if (relatorio.DelMedioRebanho.trim() !== '') {
      doc.rect(startX, cursor, tableLimit / 2, line, 'F') // superior
      doc.rect(startX, cursor, line, boxHeight, 'F') // esquerda
      doc.rect(tableLimit + startX, cursor, line, boxHeight, 'F') // direita
      doc.rect(startX, cursor + boxHeight, tableLimit / 2, line, 'F') // inferior

      doc.text(
        'DEL médio do rebanho',
        startX + smallGutterX,
        cursor + smallGutterY,
      )

      doc.text(
        `${relatorio.DelMedioRebanho}`,
        startX + smallGutterX,
        cursor + gutterY,
      )

      cursor += boxHeight
      cursor = checkAddPage(doc, cursor, boxHeight, config)
    }

    if (
      relatorio.OutrasRecomendacoesNutricaoRebanho &&
      relatorio.OutrasRecomendacoesNutricaoRebanho.trim() !== ''
    ) {
      doc.rect(startX, cursor, tableLimit, line, 'F') // superior
      doc.rect(startX, cursor, line, smallBoxHeight, 'F') // esquerda
      doc.rect(startX + tableLimit, cursor, line, smallBoxHeight, 'F') // direita

      doc.text(
        'Recomendações - Nutrição do rebanho',
        startX + tableLimit / 1000 + smallGutterX,
        cursor + smallGutterY,
      )

      cursor = renderRecomendacoes(
        doc,
        '',
        relatorio.OutrasRecomendacoesNutricaoRebanho,
        cursor,
        config,
      )
    }
  }
  return cursor
}

function protocolos(doc, relatorio, config, cursor) {
  const {
    smallBoxHeight,
    boxHeight,
    fontSize,
    line,
    mediumFontSize,
    smallGutterX,
    smallGutterY,
    startX,
    tableLimit,
    heightLimit,
    margin,
  } = config

  if (
    (relatorio.CulturaMicrobiologica != null &&
      relatorio.CulturaMicrobiologica.length != 0) ||
    (relatorio.ProtocoloParaSecagem &&
      relatorio.ProtocoloParaSecagem.trim() !== '') ||
    (relatorio.AnimaisParaSecagem != null &&
      relatorio.AnimaisParaSecagem.length != 0) ||
    (relatorio.SugestaoDeMedicamentos &&
      relatorio.SugestaoDeMedicamentos.trim() !== '') ||
    relatorio.TratamentoMastiteClinica != ''
  ) {
    cursor += config.boxHeight

    if (cursor + boxHeight * 4 > heightLimit) {
      doc.addPage()
      cursor = margin
    }

    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Protocolo de tratamento', startX, cursor)
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    cursor += smallBoxHeight - 5
    var colInicio = startX

    if (
      relatorio.CulturaMicrobiologica != null &&
      relatorio.CulturaMicrobiologica.length != 0
    ) {
      doc.rect(startX, cursor, tableLimit, line, 'F') // superior
      doc.rect(startX, cursor, line, smallBoxHeight, 'F') // esquerda
      doc.rect(startX + tableLimit, cursor, line, smallBoxHeight, 'F') // direita

      doc.text(
        'Cultura microbiológica (principais patógenos):',
        startX + tableLimit / 1000 + smallGutterX,
        cursor + smallGutterY,
      )

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      colInicio = startX

      Object.keys(relatorio.CulturaMicrobiologica).forEach(item => {
        let start = startX
        const inputValue =
          'Nome da bactéria: **' +
          relatorio.CulturaMicrobiologica[item]
            .culturaMicrobiologicaNomeBacteria +
          '** | Quantidade de casos: ' +
          relatorio.CulturaMicrobiologica[item].culturaMicrobiologicaQtdeCasos

        const arrayOfNormalAndBoldText = inputValue.split('**')
        arrayOfNormalAndBoldText.map((text, i) => {
          doc.setFont('helvetica', 'italic')
          if (i % 2 === 0) {
            doc.setFont('helvetica', 'normal')
          }
          doc.text(text, start + smallGutterX, cursor + smallGutterY)
          start = start + doc.getStringUnitWidth(text) * fontSize
        })

        doc.rect(startX, cursor, line, smallBoxHeight, 'F') // esquerda
        doc.rect(tableLimit + startX, cursor, line, smallBoxHeight, 'F') // direita
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      })

      doc.rect(startX, cursor, tableLimit, line, 'F') // superior
    }

    cursor = checkAddPage(doc, cursor, boxHeight * 4, config)

    if (
      relatorio.ProtocoloParaSecagem &&
      relatorio.ProtocoloParaSecagem.trim() !== ''
    ) {
      doc.rect(startX, cursor, tableLimit, line, 'F') // superior
      doc.rect(startX, cursor, line, smallBoxHeight, 'F') // esquerda
      doc.rect(startX + tableLimit, cursor, line, smallBoxHeight, 'F') // direita

      doc.text(
        'Protocolo de secagem:',
        startX + tableLimit / 1000 + smallGutterX,
        cursor + smallGutterY,
      )
      cursor = renderRecomendacoes(
        doc,
        '',
        relatorio.ProtocoloParaSecagem,
        cursor,
        config,
      )
    }

    cursor = checkAddPage(doc, cursor, boxHeight, config)

    if (relatorio.AnimaisParaSecagem) {
      doc.rect(startX, cursor, line, boxHeight, 'F') // esquerda
      doc.rect(startX + tableLimit, cursor, line, boxHeight, 'F') // direita

      doc.text(
        'Animais para secagem:',
        startX + tableLimit / 1000 + smallGutterX,
        cursor + smallGutterY,
      )

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, boxHeight, config)

      Object.keys(relatorio.AnimaisParaSecagem).forEach(item => {
        if (item % 3 === 1) {
          colInicio = startX + tableLimit / 3
        } else if (item % 3 === 2) {
          colInicio = startX + tableLimit - tableLimit / 3
        } else {
          colInicio = startX
        }

        doc.text(
          'Nome ou brinco: ' +
            relatorio.AnimaisParaSecagem[item].animaisParaSecagemNomeOuBrinco,
          colInicio + smallGutterX,
          cursor + smallGutterY,
        )

        if (
          item % 3 === 2 ||
          Object.keys(relatorio.AnimaisParaSecagem).length - 1 == item
        ) {
          doc.rect(startX, cursor, line, smallBoxHeight, 'F') // esquerda
          doc.rect(tableLimit + startX, cursor, line, smallBoxHeight, 'F') // direita
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }
      })

      doc.rect(startX, cursor, tableLimit, line, 'F') // superior
    }

    cursor = checkAddPage(doc, cursor, boxHeight, config)

    if (
      relatorio.SugestaoDeMedicamentos &&
      relatorio.SugestaoDeMedicamentos.trim() !== ''
    ) {
      doc.rect(startX, cursor, tableLimit, line, 'F') // superior
      doc.rect(startX, cursor, line, smallBoxHeight, 'F') // esquerda
      doc.rect(startX + tableLimit, cursor, line, smallBoxHeight, 'F') // direita

      doc.text(
        'Sugestão de medicamentos:',
        startX + tableLimit / 1000 + smallGutterX,
        cursor + smallGutterY,
      )

      cursor = renderRecomendacoes(
        doc,
        '',
        relatorio.SugestaoDeMedicamentos,
        cursor,
        config,
      )
    }
    cursor = checkAddPage(doc, cursor, boxHeight * 8, config)

    if (
      relatorio.TratamentoMastiteClinica &&
      relatorio.TratamentoMastiteClinica.trim() !== ''
    ) {
      doc.rect(startX, cursor, tableLimit, line, 'F') // superior
      doc.rect(startX, cursor, line, smallBoxHeight, 'F') // esquerda
      doc.rect(startX + tableLimit, cursor, line, smallBoxHeight, 'F') // direita

      doc.text(
        'Tratamento mastite clínica:',
        startX + tableLimit / 1000 + smallGutterX,
        cursor + smallGutterY,
      )

      cursor = renderRecomendacoes(
        doc,
        '',
        relatorio.TratamentoMastiteClinica,
        cursor,
        config,
      )
    }
    cursor = checkAddPage(doc, cursor, boxHeight * 4, config)
  }

  return cursor
}

function renderCheckboxList(doc, text, cursor, config) {
  const {
    smallBoxHeight,
    line,
    smallGutterX,
    smallGutterY,
    startX,
    tableLimit,
  } = config

  doc.rect(startX, cursor, line, smallBoxHeight, 'F')

  doc.text(text, startX + smallGutterX, cursor + smallGutterY)

  doc.rect(startX + tableLimit, cursor, line, smallBoxHeight, 'F')
}

function listDasPrincipaisConformidades(doc, relatorio, config, cursor) {
  const {
    smallBoxHeight,
    line,
    startX,
    tableLimit,
    margin,
    heightLimit,
    boxHeight,
    mediumFontSize,
    fontSize,
  } = config

  cursor += config.boxHeight

  if (cursor + boxHeight * 6 > heightLimit) {
    doc.addPage()
    cursor = margin
  }

  doc
    .setFont('helvetica', 'bold')
    .setFontSize(mediumFontSize)
    .text('Listagem das principais inconformidades', startX, cursor)
    .setFont('helvetica', 'normal')
    .setFontSize(fontSize)

  cursor += smallBoxHeight

  cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

  if (
    relatorio.step9_1 ||
    relatorio.step9_2 ||
    relatorio.step9_3 ||
    relatorio.step9_4 ||
    relatorio.step9_5 ||
    relatorio.step9_6 ||
    relatorio.step9_7
  ) {
    doc.rect(startX, cursor, tableLimit, line, 'F')
    doc.setFont('helvetica', 'bold')
    renderCheckboxList(doc, ' • Inconformidades', cursor, config)
    doc.setFont('helvetica', 'normal')
    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

    if (relatorio.step9_1) {
      renderCheckboxList(
        doc,
        'Ambiente de descanso dos animais com acúmulo de barro ( animais chegando sujos na ordenha )',
        cursor,
        config,
      )
      cursor += smallBoxHeight
    }

    if (relatorio.step9_2) {
      renderCheckboxList(
        doc,
        'Não há identificação de casos de mastite clínica nas ordenhas (teste da caneca)',
        cursor,
        config,
      )
      cursor += smallBoxHeight
    }

    if (relatorio.step9_3) {
      renderCheckboxList(
        doc,
        'Não realiza tratamento adequado de mastite',
        cursor,
        config,
      )
      cursor += smallBoxHeight
    }

    if (relatorio.step9_4) {
      renderCheckboxList(
        doc,
        'Não faz os procedimentos básicos de ordenha (pré e pós dipping)',
        cursor,
        config,
      )
      cursor += smallBoxHeight
    }

    if (relatorio.step9_5) {
      renderCheckboxList(
        doc,
        'Dieta desbalanceada (déficit nutricional)',
        cursor,
        config,
      )
      cursor += smallBoxHeight
    }

    if (relatorio.step9_6) {
      renderCheckboxList(
        doc,
        'Animais com mastite crônica sem linha de ordenha',
        cursor,
        config,
      )
      cursor += smallBoxHeight
    }

    if (relatorio.step9_7) {
      renderCheckboxList(
        doc,
        'Equipamento de ordenha desregulado ou sem manutenção a mais de 6 meses',
        cursor,
        config,
      )
      cursor += smallBoxHeight
    }

    doc.rect(startX, cursor, tableLimit, line, 'F')
  }

  doc.rect(startX, cursor, tableLimit, line, 'F')

  return cursor
}

function recomendacaoGeral(doc, relatorio, config, cursor) {
  const {
    boxHeight,
    fontSize,
    mediumFontSize,
    startX,
    smallBoxHeight,
    heightLimit,
    margin,
    tableLimit,
    line,
  } = config

  cursor += config.boxHeight

  if (cursor + boxHeight * 8 > heightLimit) {
    doc.addPage()
    cursor = margin + 10
  }
  if (
    relatorio.RecomendacoesGerais &&
    relatorio.RecomendacoesGerais.trim() !== ''
  ) {
    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Recomendações gerais', startX, cursor)
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    cursor += smallBoxHeight

    doc.rect(startX, cursor, tableLimit, line, 'F') // superior
    cursor = renderRecomendacoes(
      doc,
      'Recomendações:',
      relatorio.RecomendacoesGerais,
      cursor,
      config,
    )
    cursor += smallBoxHeight
  }
  return cursor
}

function fotosAnexo(doc, relatorio, config, cursor) {
  const {
    smallBoxHeight,
    boxHeight,
    mediumFontSize,
    fontSize,
    startX,
    tableLimit,
    heightLimit,
    margin,
  } = config

  if (
    (relatorio.ImagensInformacoesOrdenha &&
      relatorio.ImagensInformacoesOrdenha.length > 0) ||
    (relatorio.ImagensInformacoesDeRotinaDeOrdenha &&
      relatorio.ImagensInformacoesDeRotinaDeOrdenha.length > 0) ||
    (relatorio.ImagensBiosegurancaAmbienteImunidade &&
      relatorio.ImagensBiosegurancaAmbienteImunidade.length > 0) ||
    (relatorio.ImagensNutricaoDoRebanho &&
      relatorio.ImagensNutricaoDoRebanho.length > 0) ||
    (relatorio.ImagensProtologoDeTratamento &&
      relatorio.ImagensProtologoDeTratamento.length > 0)
  ) {
    cursor += smallBoxHeight
    if (cursor + boxHeight * 10 > heightLimit) {
      doc.addPage()
      cursor = margin + 10
    }

    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Imagens em anexo', startX, cursor)
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    cursor += smallBoxHeight - 5

    var colInicio = startX

    if (
      relatorio.ImagensInformacoesOrdenha &&
      relatorio.ImagensInformacoesOrdenha.length > 0
    ) {
      if (cursor + boxHeight * 6 > heightLimit) {
        doc.addPage()
        cursor = margin + 10
      }

      try {
        doc
          .setFont('helvetica', 'bold')
          .text('Imagens informações de ordenha', startX, cursor)
          .setFont('helvetica', 'normal')

        cursor += smallBoxHeight

        let imagem = new Image()
        Object.keys(relatorio.ImagensInformacoesOrdenha).forEach(item => {
          if (item % 2 === 1) {
            colInicio = startX + 10 + tableLimit / 2
          } else {
            colInicio = startX + 40
          }

          if (cursor + boxHeight * 8 > heightLimit) {
            doc.addPage()
            cursor = margin + 10
          }

          imagem.src = `${ENV_APP.apiUri}/download-image/${relatorio.ImagensInformacoesOrdenha[item].id}`

          doc.addImage(imagem, 'PNG', colInicio, cursor, 180, 180, '', 'FAST')

          if (
            item % 2 === 1 ||
            Object.keys(relatorio.ImagensInformacoesOrdenha).length - 1 == item
          ) {
            cursor += 200
          }
        })
        cursor += boxHeight //cursor += 380
        // eslint-disable-next-line no-empty
      } catch (error) {}
    }

    colInicio = startX

    if (
      relatorio.ImagensInformacoesDeRotinaDeOrdenha &&
      relatorio.ImagensInformacoesDeRotinaDeOrdenha.length > 0
    ) {
      if (cursor + boxHeight * 8 > heightLimit) {
        doc.addPage()
        cursor = margin
      }

      try {
        doc
          .setFont('helvetica', 'bold')
          .text('Imagens recomendações - rotina de ordenha', startX, cursor)
          .setFont('helvetica', 'normal')

        cursor += smallBoxHeight

        let imagem = new Image()
        Object.keys(relatorio.ImagensInformacoesDeRotinaDeOrdenha).forEach(
          item => {
            if (item % 2 === 1) {
              colInicio = startX + 10 + tableLimit / 2
            } else {
              colInicio = startX + 40
            }

            if (cursor + boxHeight * 11 > heightLimit) {
              doc.addPage()
              cursor = margin
            }
            imagem.src = `${ENV_APP.apiUri}/download-image/${relatorio.ImagensInformacoesDeRotinaDeOrdenha[item].id}`

            doc.addImage(imagem, 'PNG', colInicio, cursor, 180, 180, '', 'FAST')

            if (
              item % 2 === 1 ||
              Object.keys(relatorio.ImagensInformacoesDeRotinaDeOrdenha)
                .length -
                1 ==
                item
            ) {
              cursor += 200
            }
          },
        )
        // eslint-disable-next-line no-empty
      } catch (error) {}
      cursor += boxHeight //cursor += 380
    }

    colInicio = startX

    if (
      relatorio.ImagensBiosegurancaAmbienteImunidade &&
      relatorio.ImagensBiosegurancaAmbienteImunidade.length > 0
    ) {
      if (cursor + boxHeight * 8 > heightLimit) {
        doc.addPage()
        cursor = margin
      }

      try {
        doc
          .setFont('helvetica', 'bold')
          .text('Imagens biossegurança / ambiente / imunidade', startX, cursor)
          .setFont('helvetica', 'normal')

        cursor += smallBoxHeight

        let imagem = new Image()
        Object.keys(relatorio.ImagensBiosegurancaAmbienteImunidade).forEach(
          item => {
            if (item % 2 === 1) {
              colInicio = startX + 10 + tableLimit / 2
            } else {
              colInicio = startX + 40
            }

            if (cursor + boxHeight * 11 > heightLimit) {
              doc.addPage()
              cursor = margin
            }
            imagem.src = `${ENV_APP.apiUri}/download-image/${relatorio.ImagensBiosegurancaAmbienteImunidade[item].id}`

            doc.addImage(imagem, 'PNG', colInicio, cursor, 180, 180, '', 'FAST')

            if (
              item % 2 === 1 ||
              Object.keys(relatorio.ImagensBiosegurancaAmbienteImunidade)
                .length -
                1 ==
                item
            ) {
              cursor += 200
            }
          },
        )
        // eslint-disable-next-line no-empty
      } catch (error) {}
      cursor += boxHeight //cursor += 380
    }

    colInicio = startX

    if (
      relatorio.ImagensNutricaoDoRebanho &&
      relatorio.ImagensNutricaoDoRebanho.length > 0
    ) {
      if (cursor + boxHeight * 8 > heightLimit) {
        doc.addPage()
        cursor = margin
      }

      try {
        doc
          .setFont('helvetica', 'bold')
          .text('Imagens nutrição do rebanho', startX, cursor)
          .setFont('helvetica', 'normal')

        cursor += smallBoxHeight

        let imagem = new Image()
        Object.keys(relatorio.ImagensNutricaoDoRebanho).forEach(item => {
          if (item % 2 === 1) {
            colInicio = startX + 10 + tableLimit / 2
          } else {
            colInicio = startX + 40
          }

          if (cursor + boxHeight * 11 > heightLimit) {
            doc.addPage()
            cursor = margin
          }
          imagem.src = `${ENV_APP.apiUri}/download-image/${relatorio.ImagensNutricaoDoRebanho[item].id}`

          doc.addImage(imagem, 'PNG', colInicio, cursor, 180, 180, '', 'FAST')

          if (
            item % 2 === 1 ||
            Object.keys(relatorio.ImagensNutricaoDoRebanho).length - 1 == item
          ) {
            cursor += 200
          }
        })
        // eslint-disable-next-line no-empty
      } catch (error) {}
      cursor += boxHeight //cursor += 380
    }

    colInicio = startX

    if (
      relatorio.ImagensProtologoDeTratamento &&
      relatorio.ImagensProtologoDeTratamento.length > 0
    ) {
      if (cursor + boxHeight * 8 > heightLimit) {
        doc.addPage()
        cursor = margin
      }

      try {
        doc
          .setFont('helvetica', 'bold')
          .text('Imagens protocolo de tratamento', startX, cursor)
          .setFont('helvetica', 'normal')

        cursor += smallBoxHeight

        let imagem = new Image()
        Object.keys(relatorio.ImagensProtologoDeTratamento).forEach(item => {
          if (item % 2 === 1) {
            colInicio = startX + 10 + tableLimit / 2
          } else {
            colInicio = startX + 40
          }

          if (cursor + boxHeight * 11 > heightLimit) {
            doc.addPage()
            cursor = margin
          }
          imagem.src = `${ENV_APP.apiUri}/download-image/${relatorio.ImagensProtologoDeTratamento[item].id}`

          doc.addImage(imagem, 'PNG', colInicio, cursor, 180, 180, '', 'FAST')

          if (
            item % 2 === 1 ||
            Object.keys(relatorio.ImagensProtologoDeTratamento).length - 1 ==
              item
          ) {
            cursor += 200
          }
        })
        // eslint-disable-next-line no-empty
      } catch (error) {}
      cursor += boxHeight * 2 //cursor += 380
    }
  }

  return cursor
}

function proximaVisitaAssinaturas(doc, relatorio, config, cursor) {
  const {
    smallBoxHeight,
    boxHeight,
    tableLimit,
    startX,
    smallGutterY,
    line,
    heightLimit,
    margin,
  } = config

  if (cursor + boxHeight * 8 > heightLimit) {
    doc.addPage()
    cursor = margin
  }

  cursor += config.boxHeight

  const proximaVisita = relatorio.DataProximaVisita
    ? relatorio.DataProximaVisita
    : ''

  doc.text('Data da próxima visita', tableLimit / 2 - 30, cursor + boxHeight)
  doc.text(
    proximaVisita,
    tableLimit / 2 - 5,
    cursor + boxHeight + smallBoxHeight,
  )

  cursor += boxHeight * 2

  const produtor = new Image()

  if (relatorio.AssinaturaProdutorOuResponsavel === '1') {
    produtor.src = relatorio.AssinaturaProdutor
    doc.addImage(
      produtor,
      'PNG',
      startX + 50,
      cursor - 140,
      100,
      145,
      '',
      'FAST',
      270,
    )
  } else {
    produtor.src = relatorio.AssinaturaResponsavel
    doc.addImage(
      produtor,
      'PNG',
      startX + 50,
      cursor - 140,
      100,
      145,
      '',
      'FAST',
      270,
    )
  }

  const consultor = new Image()
  consultor.src = relatorio.AssinaturaConsultor

  if (relatorio.AssinaturaConsultor) {
    doc.addImage(
      consultor,
      'PNG',
      tableLimit - 180,
      cursor - 140,
      100,
      145,
      '',
      'FAST',
      270,
    )
  }

  doc.rect(startX, cursor + 100, tableLimit / 2.1, line, 'F')

  if (relatorio.AssinaturaProdutorOuResponsavel === '1') {
    doc.text(
      relatorio.fazenda.produtor.Nome,
      tableLimit / 3.5,
      cursor + 100 + smallGutterY,
      null,
      null,
      'center',
    )
    doc.text(
      'Assinatura produtor(a)',
      tableLimit / 3.5,
      cursor + 115 + smallGutterY,
      null,
      null,
      'center',
    )
  } else {
    doc.text(
      relatorio.NomeResponsavel,
      tableLimit / 3.5,
      cursor + 100 + smallGutterY,
      null,
      null,
      'center',
    )
    doc.text(
      'Assinatura do responsável',
      tableLimit / 3.5,
      cursor + 115 + smallGutterY,
      null,
      null,
      'center',
    )
  }

  doc.rect(startX + 280, cursor + 100, tableLimit / 2.1, line, 'F')
  doc.text(
    relatorio.consultor.pessoafisica.Nome,
    startX + 420,
    cursor + 100 + smallGutterY,
    null,
    null,
    'center',
  )
  doc.text(
    'Assinatura consultor(a)',
    startX + 420,
    cursor + 115 + smallGutterY,
    null,
    null,
    'center',
  )

  cursor += boxHeight * 2

  return cursor
}

function rodapeImpressao(doc, config, cursor, text) {
  const { boxHeight, heightLimit, margin } = config
  const docWidth = doc.internal.pageSize.width
  const texto =
    utilsRelatorio.getDataAtual() +
    '       ' +
    `${ENV_APP.relUri}` +
    '        Pag. ' +
    text

  if (cursor + boxHeight * 2 > heightLimit) {
    doc.addPage()
    cursor = margin
  }

  doc.text(texto, docWidth / 2, heightLimit + 7, 'center')
}

export default data => {
  return new Promise(function (resolve, reject) {
    const MARGIN = 25
    const realStartY = 95
    var doc = new JsPDF({
      format: 'a4',
      compress: true,
      unit: 'pt',
    })
    var config = {
      startY: realStartY,
      startX: MARGIN,
      smallGutterY: 12,
      smallGutterX: 6,
      line: 0.3,
      tableLimit: doc.internal.pageSize.width - MARGIN - MARGIN,
      heightLimit: doc.internal.pageSize.height - MARGIN,
      margin: MARGIN,
      lineColor: '#000',
      boxHeight: 27,
      smallBoxHeight: 18,
      gutterX: 14,
      gutterY: 23,
      smallFontSize: 6.5,
      fontSize: 10,
      largefontSize: 16,
      mediumFontSize: 12,
      logo: 'img/' + `${ENV_APP.imgFileLogin}`,
      bodyStarY: realStartY + 25 * 8.5,
    }

    try {
      HtmlToCanvas(document.querySelector('#chart-rebanho')).then(canvas => {
        let dataURL = canvas.toDataURL()
        doc.addImage(
          dataURL,
          'PNG',
          config.startX * 2.8,
          config.startY + config.boxHeight * 9 + 90,
          456,
          200,
        )
        let cursor = 0
        header(doc, config, data)
        subHeader(doc, data, config)
        config.startY = config.startY + config.boxHeight * 4 + 40
        indicadoresQualidade(doc, data, config)
        config.startY = config.startY + config.boxHeight * 4 + 70
        cursor = composicaoRebanho(doc, data, config, cursor)
        config.startY = cursor + config.boxHeight
        cursor = acompanhamentoOrdenha(doc, data, config, cursor)
        config.startY = cursor + config.boxHeight
        cursor = recomendacaoOrdenha(doc, data, config, cursor)
        config.startY = cursor + config.boxHeight
        cursor = biosseguranca(doc, data, config, cursor)
        config.startY = cursor + config.boxHeight
        cursor = nutricaoRebanho(doc, data, config, cursor)
        config.startY = cursor + config.boxHeight
        cursor = protocolos(doc, data, config, cursor)
        config.startY = cursor + config.boxHeight
        cursor = listDasPrincipaisConformidades(doc, data, config, cursor)
        config.startY = cursor + config.boxHeight
        cursor = recomendacaoGeral(doc, data, config, cursor)
        config.startY = cursor + config.boxHeight
        cursor = fotosAnexo(doc, data, config, cursor)
        config.startY = cursor + config.boxHeight
        cursor = proximaVisitaAssinaturas(doc, data, config, cursor)
        const pageCount = doc.internal.getNumberOfPages()

        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i)
          rodapeImpressao(
            doc,
            config,
            cursor + config.boxHeight,
            String(i) + ' de ' + String(pageCount),
          )
        }
        doc
          .save(
            `relatorio-visita-ccs-${data.NumeroVisita}-${data.fazenda.pessoajuridica.NomeFantasia}.pdf`,
            { returnPromise: true },
          )
          .then(() => {
            return resolve()
          })
      })
    } catch (err) {
      reject(err)
    }
  })
}
