<template>
  <v-card
    v-if="
      relatorio.EscoreCorporalVacasSecas ||
      relatorio.EscoreCorporalVacasPreParto ||
      relatorio.EscoreCorporalVacasRecemParidas ||
      relatorio.DelMedioRebanho
    "
    class="ma-4"
  >
    <v-card-title style="text-align: left">Nutrição do rebanho</v-card-title>
    <v-card-text>
      <v-row class="pa-3">
        <v-col
          v-if="relatorio.EscoreCorporalVacasSecas"
          cols="12"
          sm="6"
          lg="6"
          xl="6"
          class="col-bordered"
        >
          <span class="key-title">Escore corporal vacas secas: </span>
          <span class="value-title">{{
            relatorio.EscoreCorporalVacasSecas
          }}</span>
        </v-col>
        <v-col
          v-if="relatorio.DietaVacasLactacaoCondizenteComProducao"
          cols="12"
          sm="6"
          lg="6"
          xl="6"
          class="col-bordered"
        >
          <span class="key-title">
            Dieta das vacas em lactação condizente com produção?
          </span>
          <span class="value-title">{{
            relatorio.DietaVacasLactacaoCondizenteComProducao
          }}</span>
        </v-col>
        <v-col
          v-if="relatorio.EscoreCorporalVacasPreParto"
          cols="12"
          sm="6"
          lg="6"
          xl="6"
          class="col-bordered"
        >
          <span class="key-title">Escore corporal vacas pré parto: </span>
          <span class="value-title">{{
            relatorio.EscoreCorporalVacasPreParto
          }}</span>
        </v-col>
        <v-col
          v-if="relatorio.ProdutividadeCondizenteComPotencialProdutivo"
          cols="12"
          sm="6"
          lg="6"
          xl="6"
          class="col-bordered"
        >
          <span class="key-title">
            Produtividade das vacas condiz com potencial produtivo?
          </span>
          <span class="value-title">{{
            relatorio.ProdutividadeCondizenteComPotencialProdutivo
          }}</span>
        </v-col>
        <v-col
          v-if="relatorio.EscoreCorporalVacasRecemParidas"
          cols="12"
          sm="6"
          lg="6"
          xl="6"
          class="col-bordered"
        >
          <span class="key-title"> Escore corporal vacas recém paridas: </span>
          <span class="value-title">{{
            relatorio.EscoreCorporalVacasRecemParidas
          }}</span>
        </v-col>
        <v-col
          v-if="relatorio.DietaPrePartoAnionica"
          cols="12"
          sm="6"
          lg="6"
          xl="6"
          class="col-bordered"
        >
          <span class="key-title">
            Tipo de dieta no pré-parto é aniônica?
          </span>
          <span class="value-title">{{ relatorio.DietaPrePartoAnionica }}</span>
        </v-col>
        <v-col
          v-if="relatorio.DelMedioRebanho"
          cols="12"
          sm="6"
          lg="6"
          xl="6"
          class="col-bordered"
        >
          <span class="key-title"> DEL médio do rebanho: </span>
          <span class="value-title">{{ relatorio.DelMedioRebanho }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'NutricaoRebanhoCCS',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {},
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
