<template>
  <v-card class="ma-4">
    <v-card-title style="text-align: left"
      >Listagem das principais inconformidades</v-card-title
    >
    <v-card-text>
      <v-row class="pa-3">
        <v-col
          v-if="
            relatorio.step9_1 ||
            relatorio.step9_2 ||
            relatorio.step9_3 ||
            relatorio.step9_4 ||
            relatorio.step9_5 ||
            relatorio.step9_6 ||
            relatorio.step9_7
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Inconformidades</span>
          <v-col v-if="relatorio.step9_1" cols="12">
            <span class="value-title"
              >Ambiente de descanso dos animais com acúmulo de barro ( animais
              chegando sujos na ordenha )
            </span>
          </v-col>
          <v-col v-if="relatorio.step9_2" cols="12">
            <span class="value-title"
              >Não há identificação de casos de mastite clínica nas ordenhas
              (teste da caneca)
            </span>
          </v-col>
          <v-col v-if="relatorio.step9_3" cols="12">
            <span class="value-title">
              Não realiza tratamento adequado de mastite
            </span>
          </v-col>
          <v-col v-if="relatorio.step9_4" cols="12">
            <span class="value-title"
              >Não faz os procedimentos básicos de ordenha (pré e pós dipping)
            </span>
          </v-col>
          <v-col v-if="relatorio.step9_5" cols="12">
            <span class="value-title"
              >Dieta desbalanceada (déficit nutricional)
            </span>
          </v-col>
          <v-col v-if="relatorio.step9_6" cols="12">
            <span class="value-title"
              >Animais com mastite crônica sem linha de ordenha
            </span>
          </v-col>
          <v-col v-if="relatorio.step9_7" cols="12">
            <span class="value-title"
              >Equipamento de ordenha desregulado ou sem manutenção a mais de 6
              meses
            </span>
          </v-col>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'ManejoReprodutivo',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {
    maskIdentify(raw) {
      return raw ? raw.split('|') : ['Nenhum animal identificado']
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
  line-height: 40px;
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
