var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"ma-4"},[_c('v-card-title',{staticStyle:{"text-align":"left"}},[_vm._v("Listagem das principais inconformidades")]),_c('v-card-text',[_c('v-row',{staticClass:"pa-3"},[(
          _vm.relatorio.step9_1 ||
          _vm.relatorio.step9_2 ||
          _vm.relatorio.step9_3 ||
          _vm.relatorio.step9_4 ||
          _vm.relatorio.step9_5 ||
          _vm.relatorio.step9_6 ||
          _vm.relatorio.step9_7
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Inconformidades")]),(_vm.relatorio.step9_1)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Ambiente de descanso dos animais com acúmulo de barro ( animais chegando sujos na ordenha ) ")])]):_vm._e(),(_vm.relatorio.step9_2)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Não há identificação de casos de mastite clínica nas ordenhas (teste da caneca) ")])]):_vm._e(),(_vm.relatorio.step9_3)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v(" Não realiza tratamento adequado de mastite ")])]):_vm._e(),(_vm.relatorio.step9_4)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Não faz os procedimentos básicos de ordenha (pré e pós dipping) ")])]):_vm._e(),(_vm.relatorio.step9_5)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Dieta desbalanceada (déficit nutricional) ")])]):_vm._e(),(_vm.relatorio.step9_6)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Animais com mastite crônica sem linha de ordenha ")])]):_vm._e(),(_vm.relatorio.step9_7)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Equipamento de ordenha desregulado ou sem manutenção a mais de 6 meses ")])]):_vm._e()],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }